.page-menu {
  height: 100vh;

  h1 {
    position: absolute;
    left: 50%;
    bottom: calc(45% + (390px / 2));
    transform: translateX(-50%) translateY(50%);
    color: #fff;
    font-size: 3.75rem;
    line-height: 1;
    margin-bottom: calc(2.6rem + 10px);
  }

  .app-page__container {
    width: 100%;
    height: 100%;
  }

  .app-cardselection {
    position: absolute;
    left: 50%;
    bottom: 45%;
    width: 100%;
    transform: translateX(-50%) translateY(50%);

    &__item {
      display: inline-block;
      width: 276px;
      height: 350px;
      background-color: $primaryColor;
      border-radius: 20px;
      color: #fff;
      margin: 1.25rem;
      padding: 3rem 1.5rem;
      opacity: 0;

      &#menu-waive {
        figure {
          img {
            width: 90%;
            height: 90%;
          }
        }
      }

      * {
        pointer-events: none;
      }
    }
  }
}
