.printable-results {
  padding: 0 !important;
  position: fixed;
  top: 0;
  left: 0;
  z-index: -1;
  transform: none;
  width: 210mm !important;
  min-height: 297mm !important;
  margin-top: 0;
  font-family: 'Arial';
  font-size: 16px;

  transform: translate(-100%, -100%);

  h1 {
    font-size: 1.75rem;
  }

  .app-page__container {
    position: relative !important;
    width: 100%;
    scroll-snap-type: initial;
    scroll-snap-points-y: initial;
    scroll-snap-type: y initial;
    overflow-x: initial;
    overflow-y: initial;
    justify-content: initial;
  }

  * {
    animation: none !important;
  }

  .gfx-buildings {
    width: 100%;
  }
  .gfx-othertrees {
    width: 100%;
  }

  .gfx-trees {
    transform: translateY(50px);
    .co2-cloud_amount {
      opacity: 0 !important;
    }
    .gfx-trees__section {
      position: absolute;
      bottom: 0;
      left: 0;
      .gfx-treegroup {
        &:nth-child(1) {
          z-index: 10;
          opacity: 0.5;
        }
        &:nth-child(2) {
          z-index: 11;
          opacity: 0.6;
        }
        &:nth-child(3) {
          z-index: 12;
          opacity: 0.7;
        }
        &:nth-child(4) {
          z-index: 13;
        }
      }
    }
    .gfx-tree {
      .tree-overlay {
        // opacity: 0.5 !important;
        display: none;
      }
      #gfx-tree1_mask0 {
        path {
          fill: #ff0000;
        }
      }
    }
  }

  .graph-bar {
    .bar-wrap {
      transform: scale(1) !important;
    }
    .bar-breakpoint {
      .graph-breakpoint {
        opacity: 1;
        .breakpoint-node {
          left: auto;
          right: 0;
          border-right: 2px solid #818A91;
          border-left: 0;
          text-align: right;
        }
      }
    }
  }
  .results {
    display: block;
    width: 100%;
    text-align: center;
    padding: 20px;
    background-color: #F8FCFF;
    .total {
      font-size: 32px;
      padding: 8px;
      span {
        margin-left: 8px;
      }
    }
    .annotate {
      display: block;
      font-size: 12px;
      margin-top: 12px;
    }
  }
  .results-main-bg {
    display: block;
    width: 210mm;
    height: 270px;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .results-main {
    padding: 20px;
    z-index: 200;
    .app-qnnre__gfx {
      // display: none;
      .gfx-trees {
        &:before {
          z-index: 10;
          position: absolute;
          bottom: 0;
          left: 0;
          top: auto;
          display: none !important;
        }
        &:after {
          z-index: 100;
          height: 80px;
          background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, #ffffff 74%, #ffffff 100%) !important;
        }
        .gfx-trees__section {
          position: absolute;
          bottom: 0;
          z-index: 50;
          &#trees__section1 {
            left: 0;
            right: auto;
            transform: translateX(-100px) scale(0.7) translateY(40%);
          }
          &#trees__section2 {
            left: auto;
            right: 0;
            transform: translateX(-300px) scale(0.7) translateY(40%);
          }
        }
      }
    }
    .total {
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      display: block;
      width: 100%;
      text-align: center;
    }
    .co2-ground {
      position: relative;
      bottom: auto;
      left: auto;
      right: auto;
      display: block;
      width: 90%;
      text-align: center;
    }
  }

  .results-breakdown {
    padding: 20px 16px;
    min-height: initial;
    h1 {
      display: none;
    }
    .app-chart {
      .app-chart_graph {
        min-height: 180px;
        transform: scale(0.8);
      }
      .app-chart_breakdown {
        transform: scale(0.8);
        .app-chart_detail {
          opacity: 1;
          margin: 0.5rem;
          width: calc((1 / 3 * 100%) - 1rem);
          max-width: calc(1 / 3 * 100%);

          .app-chart_detail__label {
            font-size: 16px;
            &:before {
              width: 10px;
              height: 10px;
              content: "";
              border-radius: 50%;
              background-color: var(--chartDetailColor);
              margin-right: 0.5rem;
              position: relative;
              top: auto;
              left: auto;
            }
          }
          .app-chart_detail__value {
            font-size: 24px;
          }
          .app-chart_detail__description {
            font-size: 16px;
          }
        }
      }
    }
    .graph-bar__container {
      width: 80%;
    }
    .graph-bar__pre {
      top: calc(100% + 0.5rem);
      left: 0;
      right: auto;
      margin-top: 0.5rem;
      opacity: 1;
    }
    .graph-bar__post {
      top: calc(100% + 0.5rem);
      left: auto;
      right: 0;
      margin-top: 0.5rem;
      opacity: 1;
    }
  }

  .results-tips {
    padding: 20px 16px;
    .app-article {
      transform: scale(0.8);
      min-height: 200px;
      flex-direction: row;
      .app-article__hero {
        width: 40%;
        flex-basis: 40%;
        margin-left: -12px;
        img {
          width: 100%;
        }
      }
      .app-article__content {
        width: 60%;
        flex-basis: 60%;
        padding: 0 12px;
        padding-left: 20px;
      }
    }
  }

  .result-annual {
    &.printable {
      opacity: 1;
    }
  }

  .results-monthly-tracking {
    &.bar-chart-v2 {
      &.print {
        display: block;
        opacity: 1;
      }
    }

    .set-target {
      display: none;
    }

    .reset-target {
      display: none;
    }
  }

  .timestamp {
    position: absolute;
    bottom: 0;
    right: 0;
    padding-bottom: 8px;
    padding-right: 8px;
    font-size: 12px;
  }
}

@media print {
  @page {
    size: auto;
    margin: 0mm;
  }

  * {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body {
    font-family: 'geomanist', Arial;
    color: #4f5a60;
    margin: 0;
    padding: 1rem 0;
    margin-top: -2rem;
  }

  #printable {
    max-width: 620px;
    margin: 0 auto;

    .print-pagebreak {
      page-break-before: always;
    }

    .printable-border {
      border: 1px solid #ced5dc;
      padding: 3rem 1rem 1rem;
    }

    .page-title {
      font-size: 1rem;
      text-align: center;
      margin-bottom: 2.5rem;
    }

    .summary {
      margin-bottom: 5rem;
    }

    .app-infocard {
      padding: 1rem;
    }

    .floormap-viewer {
      height: 200px;
    }

    .form-cta {
      display: none;
    }

    .terms-and-conditions {
      display: block;
      margin-top: 120px;
      text-align: left;

      .extTitle {
        font-size: 18px;
        font-weight: 500;
        margin-top: 16px;
        margin-bottom: 10px;
      }
    }

    .logo {
      display: block;
      text-align: left;
      position: absolute;
      top: 1.5rem;
      left: 0;

      img {
        width: 100px;
        display: inline-block;
      }
    }

    .terms-and-conditions {
      padding: 1rem 0;
    }

    .detail_section {
      .detail_section--content,
      .detail_section--title {
        .label {
          width: 45% !important;
        }

        .data {
          width: 55% !important;
        }
      }

      .detail_section--title {
        margin-bottom: 0.5rem;
        font-size: 1.2rem;

        // width: 33%;

      }

      .detail_section--content {
        // width: 67%;
        padding-bottom: 1rem;

        .label {
          width: 45%;
          font-size: 0.9rem;
        }

        .data {
          width: 55%;
          font-size: 0.9rem;
        }

        .u-grid {
          padding-bottom: 0.5rem;
        }

        span {
          display: block;
        }

        .thumb {
          display: inline-block;
          width: 64px;
          height: 49px;
          border-radius: 5px;
          overflow: hidden;
          margin-right: 0.5rem;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }

      .detail_section--checks {
        .u-grid {
          padding-bottom: 0;

          .u-cell {
            padding-left: 0;
            padding-right: 0;
          }
        }

        .lm--formItem-label {
          display: none;
        }

        .lm--checkbox {
          .lm--checkbox-label {
            font-size: 0.8rem;

            &:before {
              flex-basis: 2.5rem;
            }
          }
        }
      }

      .u-cell {
        width: 67%;

        &.label {
          text-align: right;
          width: 33%;
        }

        &.data {
          text-align: left;
        }
      }
    }
  }
}
