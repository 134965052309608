.content-node {
  display: block;
  width: 100%;

  // margin-bottom: 1rem;
  padding: 0.5rem;

  &_label {
    display: block;
    font-size: 0.75rem;
    text-transform: uppercase;
    color: rgba(129, 138, 145, 1);
    margin-bottom: 0.25rem;
    text-align: left;
  }

  &_value {
    display: flex;
    font-size: 1rem;
    font-weight: 500;
    text-align: left;

    span {
      flex-grow: 1;
      flex-basis: 50%;
      padding: 0 0.5rem;

      &:first-child {
        padding: 0;
      }

      small {
        color: #818a91;
        font-size: 0.875rem;
        margin-left: 0.5rem;
      }
    }
  }

  &.highlight {
    .content-node_value {
      font-size: 1.875rem;
      color: #00B0B2;
    }
  }

  &.bigger {
    .content-node_value {
      font-size: 1.875rem;
    }
  }
}
