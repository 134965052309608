.__results {
  scroll-snap-type: mandatory;
  scroll-snap-points-y: repeat(100vh);
  scroll-snap-type: y mandatory;
  overflow-x: hidden;
  overflow-y: scroll;
}

.page-results {
  background-color: #ffffff;

  .app-page__container {
    position: relative;
    width: 100%;
  }

  .app-page__blade {
    padding: 4rem 2rem;
    scroll-snap-align: start;

    &.results-tips,
    &.results-share {
      scroll-snap-align: end;
    }
  }

  .results-main {
    background-color: #F8FCFF;
    background-repeat: no-repeat;
    background-position: center 68%;
    overflow: hidden;

    h1 {
      position: relative;
      z-index: 20;
    }

    .app-qnnre__gfx {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      z-index: 10;

      &.app-qnnre__bg {
        top: 0;
        bottom: auto;
        width: 100%;
        height: 100%;
        z-index: 5;
        background-color: #F8FCFF;
        overflow: hidden;
      }

      .gfx-buildings {
        bottom: 70%;
        top: auto;
      }

      .gfx-othertrees {
        bottom: 70%;
        top: auto;
      }

      .gfx-trees {
        position: relative;

        &:before {
          content: "";
          display: block;
          width: 100%;
          height: calc(100% - 50px);
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 1;
          background-color: #ffffff;
        }

        &:after {
          content: "";
          display: block;
          width: 100%;
          height: 70px;
          position: absolute;
          bottom: 0;
          left: 0;
          z-index: 3;
          @include bg-gradient-fog;
        }

        .gfx-treegroup {
          width: 100%;

          .gfx-tree {
            $affectDelay: 3;
            $cloudDelay: 2.8;

            @for $i from 1 through 20 {
              &:nth-child(#{$i}) {
                &.is-affected {
                  .co2-cloud_amount {
                    animation-delay: #{$cloudDelay}s;
                  }

                  animation-delay: #{$affectDelay}s;
                }
              }

              $affectDelay : $affectDelay + 0.3;
              $cloudDelay : $cloudDelay + 0.3;
            }
          }
        }

        .gfx-trees__section {
          transform: translateY(-13%);
          z-index: 2;
        }
      }

      .gfx-skycloud,
      [class$='-cloud'] {
        position: absolute;
        top: calc(var(--cloudY) * 1px);
        left: calc(var(--cloudX) * 1px);
        z-index: 3;

        &.gfx-cloud1 {
          animation: cloudMovement 50s linear infinite;
        }

        &.gfx-cloud2 {
          animation: cloudMovement 50s linear 2s infinite;
        }

        &.gfx-cloud3 {
          animation: cloudMovement 50s linear 3s infinite;
        }
      }
    }

    .total {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: row;

      font-weight: 500;
      font-size: 3.5rem;
      line-height: 1;
      color: #818A91;

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 100;

      &.appear {
        .co2-cloud_amount {
          .co2-gfx_cloud_1 {
            opacity: 0;
            animation: cloudAppear1 1s ease-in 1s forwards;
          }

          .co2-gfx_cloud_2 {
            opacity: 0;
            animation: cloudAppear2 1s ease-in 1.5s forwards;
          }

          .co2-gfx_cloud_content {
            opacity: 0;
            animation: totalAppear 1s ease-in 1.8s forwards;
          }

          & + span {
            opacity: 0;
            animation: unitAppear 1s ease-in 2s forwards;
          }
        }
      }
    }

    .co2-ground {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: absolute;
      bottom: 50%;
      left: 50%;
      transform: translateX(-50%) translateY(120%);
      width: 60%;
      padding: 2rem;
      height: 200px;
      text-align: center;
      font-size: 1.5rem;
      z-index: 15;

      &.appear {
        opacity: 0;
        animation: totalDescriptionAppear 1s ease 3s forwards;
      }

      &::before,
      &::after {
        display: block;
        width: 30%;
        height: 100%;
        content: "";

        background-repeat: no-repeat;
        position: absolute;
        top: 0;
        z-index: 1;

      }

      &:before {
        background-size: 100%;
        left: 0;
      }

      &:after {
        background-size: 100%;
        right: 0;
      }

      span {
        z-index: 10;
        position: relative;

        &.annotate {
          font-size: 0.67em;
          margin-top: 0.5rem;
        }
      }
    }
  }

  .results-breakdown {
    min-height: 100vh;
    background-color: #ffffff;

    .blade-container {
      width: 100%;
    }

    .results-breakdown-info {
      cursor: pointer;
    }

    .app-chart {
      &.appear {
        .app-chart_detail {
          animation-play-state: running !important;
        }
      }

      .app-chart_breakdown {
        .app-chart_detail {
          opacity: 0;
          animation-play-state: paused;

          $appearBdDelay: 2;

          @for $i from 1 through 10 {
            &:nth-child(#{$i}) {
              animation: chartBdAppear 1s ease-out #{$appearBdDelay}s forwards;
              animation-play-state: paused;
            }

            $appearBdDelay : $appearBdDelay + 0.5;
          }
        }
      }
    }

    &-title {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      margin-bottom: 1rem;

      h1 {
        margin-bottom: 0;

        @media screen and (max-width:736px) {
          font-size: 1rem;
        }
      }

      img {
        margin-left: 0.5rem;
        width: 35px;

        @media screen and (max-width:736px) {
          width: 22px;
        }
      }
    }
  }

  .results-tips {
    min-height: min-content;
    background-color: #F8FCFF;

    .app-article {
      min-height: 300px;

      &.is-crosswise {
        .app-article__hero {
          flex-basis: 40%;
        }
      }

      .app-article__content {
        justify-content: center;
      }
    }

    .hero-tree {
      position: relative;
      height: 100%;
      top: auto;
      left: auto;
      transform: translate(0, 0);

      .gfx-featpodium {
        position: absolute;
        transform: scale(0.7);
        bottom: 0;
      }
    }

    &__download {
      font-size: 1rem;
      white-space: nowrap;
    }

    .app-article.is-crosswise {
      min-height: unset;

      .app-article__header {
        padding: 0.5rem 0;
        font-size: 1.125rem;
        font-weight: 500;
      }

      .app-article__hero {
        justify-content: center;
        min-height: unset;
        flex-basis: 0%;

        img {
          width: 96px;
        }
      }

      .app-article__content {
        align-items: flex-start;
        flex-basis: 70%;
      }

      .app-article__saving {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
        border-radius: 20px;
        background: #E6F7F7;
        color: #00B0B2;
        font-size: 0.75rem;
        padding: 0 0.5rem 0 0.25rem;
        font-weight: 600;
      }
    }

    .blade-container {
      max-width: 775px;
      text-align: center;
    }
  }

  .results-share {
    min-height: min-content;
    padding-bottom: 10rem;
    background-color: #ffffff;

    // height: 80vh;
    .blade-container {
      width: 100%;
      max-width: unset;
    }

    .social-media {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;

      &.animate {
        .app-article {
          .app-article__hero {
            opacity: 0;
          }

          .app-article__header {
            opacity: 0;
          }

          .app-article__content {
            opacity: 0;
          }
        }

        &.animate-stagger {
          &.appear {
            .app-article {
              $appearFeatLitItemDelay: 0;

              @for $i from 1 through 10 {
                &:nth-child(#{$i}) {
                  .app-article__hero {
                    $appearFeatLitItemDelay : $appearFeatLitItemDelay + 0.1;
                    animation: appear 1s ease-out #{$appearFeatLitItemDelay}s forwards;
                  }

                  .app-article__header {
                    $appearFeatLitItemDelay : $appearFeatLitItemDelay + 0.2;
                    animation: appear 1s ease-out #{$appearFeatLitItemDelay}s forwards;
                  }

                  .app-article__content {
                    $appearFeatLitItemDelay : $appearFeatLitItemDelay + 0.2;
                    animation: appear 1s ease-out #{$appearFeatLitItemDelay}s forwards;
                  }
                }

                $appearFeatLitItemDelay : $appearFeatLitItemDelay + 0.3;
              }
            }
          }
        }
      }

      .app-article {
        box-shadow: 0px 0px 8px rgba(231, 235, 238, 0.6);
        border-radius: 5px;
        padding: 0;
        max-width: 32%;
        margin: 1rem;
        background-color: #ffffff;

        &__header {
          padding: 0;

          h1 {
            margin-top: 1rem;
            font-size: 1.25rem;
          }
        }

        &__hero {
          min-height: 105px;
          overflow: hidden;
          position: relative;
          justify-content: center;
          padding: 1rem 0 0;

          .gfx-saveresult,
          .gfx-shareresult {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
          }

          .gfx-cloud1 {
            position: absolute;
            left: 0;
            bottom: 30px;
            transform: translateX(-30%);
          }

          .gfx-cloud2 {
            position: absolute;
            right: 0;
            top: 30px;
            transform: translateX(30%);
          }

        }

        &__content {
          padding: 0 1rem 1rem 1rem;
          margin-bottom: 0;
          justify-content: space-between;
          align-items: center;
          height: 100%;
        }

        button,
        a {
          max-width: 180px;
          align-self: center;
          margin-top: 1.5rem;
        }
      }
    }
  }

  .co2-cloud_amount {
    min-width: 300px;
    height: 125px;
    position: relative;
    margin-right: 1rem;

    .co2-gfx_cloud {
      position: absolute;
      z-index: 1;
      line-height: 0;

      &_2 {
        top: -1.3rem;
        right: -1.5rem;
      }
    }

    .co2-gfx_cloud_content {
      font-weight: 500;
      font-size: 3.5rem;
      line-height: 1;
      color: #4F5A60;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      z-index: 10;
      position: relative;
      min-height: 100%;
    }
  }

  .results-monthly-tracking {
    &.bar-chart-v2 {
      padding: 0;
      min-height: 100%;
      margin-top: 2rem;
      opacity: 0;
      animation: appear 1.5s ease-out 1s forwards;
      animation-play-state: paused;

      .top-chart-content {
        text-align: center;
        margin: 0 1rem 2rem;

        p {
          font-weight: 600;
        }
      }

      .set-target {
        width: 100%;
        text-align: center;
        padding: 0 1rem 1rem;
        font-size: 0.875rem;

        span {
          cursor: pointer;
        }
      }

      .bar-chart {
        padding: 1rem 3rem;

        @media screen and (max-width: 807px) {
          padding: 0.5rem 0.75rem 0.75rem;
        }

        &.print {
          margin: 0 auto;
        }
      }

      .chart-title {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;

        img {
          cursor: pointer;
          margin-left: 0.5rem;
        }
      }

      &.animate-appear {
        animation-play-state: running;
      }
    }
  }

  .result-annual {
    min-height: unset;
    padding: 2rem;
    opacity: 0;
    animation: appear 1.5s ease-out 1s forwards;
    animation-play-state: paused;

    .blade-container {
      width: 100%;
    }

    &.animate-appear {
      animation-play-state: running;
    }
  }
}

.app-modal.target-confirm {
  > div:first-child {
    width: 100%;
  }

  .app-form form {
    padding: 1rem 0 0;
  }

  .modal-ctas {

    margin-top: 1rem;
    flex-direction: row-reverse;

    button {
      margin: 5px;
      border: 1px solid #00b0b3;
      flex: 1;
      min-width: 135px;
    }
  }

  .target-model {
    width: 100%;

    .field-cta {
      flex-direction: row;
      justify-content: center;
      align-items: flex-start;
      margin-bottom: 0;

      .cta-button {
        &:not(:first-of-type) {
          margin-left: 5px;
          order: 2;
        }

        + .cta-button {
          margin-bottom: 0;
        }
      }
    }
  }
}

.FDMore {
  display: flex;
  flex-direction: column;

  .cateInfor-single {
    h4,
    span {
      text-align: left;
    }

    h4,
    h5 {
      font-weight: 600;
      margin-bottom: 0.2rem;
    }

    &:not(:last-child) {
      margin-bottom: 1.5rem;
    }
  }
}
