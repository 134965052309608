.maintenance-banner {
  position: relative;
  z-index: 100;

  &__text {
    padding: 10px 5%;
    background: #d9edf7;
    color: #31708f;
    text-align: center;
    font-size: 14px;
  }

  &__close-icon {
    position: absolute;
    top: 7px;
    right: 10px;
    font-size: 18px;
    cursor: pointer;
  }
}
