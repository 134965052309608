$textHighlight: 0 0 0 transparent, 0 0 5px rgb(250, 250, 250),
    0 0 20px rgb(250 250 250 / 100%), 0 0 5px rgba(250, 250, 250, 2),
    0 0 5px rgba(250, 250, 250, 1), 0 0 5px rgba(250, 250, 250, 1),
    0 0 0 rgba(250, 250, 250, 1);

.app-herolanding {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 3.5rem 2rem;
  width: 100%;
  height: 100%;

  background-color: #F8FCFF;
  position: relative;

  &:after {
    content: '';
    background-color: #ffffff;
    width: 100%;
    height: 26%;
    max-height: 200px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }

  &.animate {
    .hero-tree {
      animation: heroAppear 1s ease-out 1.5s forwards;
      .gfx-podium_star {
        .gfx-treehero {
          animation: heroTreeAppear 1.5s linear 1.5s forwards;
        }
        .gfx-co2logo {
          animation: heroCO2Appear 1.5s linear 1.5s forwards;
        }
      }
    }

    .hero-content {
      h1 {
        animation: appear 1s ease-out 5s forwards;
      }
      h2 {
        animation: appear 1s ease-out 5.4s forwards;
      } 

      p,
      .text-lighting {
        animation: appear 1s ease-out 5.7s forwards;
      }
      
      .co2e-guessRaintrees {
        animation: appear 1s ease-out 5.9s forwards;
      }
    }
  }

  .hero-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    max-width: 807px;
    padding: 1rem;
    position: relative;
    z-index: 10;
    h1 {
      margin-bottom: 1rem;
      opacity: 0;
      max-width: 777px;
    }

    h2 {
      font-size: 1.5rem;
      margin-bottom: 1rem;
      color: #818A91;
      opacity: 0;
    }

    p {
      font-size: 1rem;
      line-height: 1.5;
      color: var(--color-charcoal);
      margin: 0.7rem 0;
      opacity: 0;

      span.txt-bold {
        font-weight: 600;
      }

      .hightlight-txt {
        color: #ff9d5a;
        font-weight: 600;
      }
    }

    .landing-app__download {
      margin-top: 1rem;
      font-size: 1rem;
      opacity: 0;
    }

    .text-lighting,
    .hero-link-results {
      text-shadow: $textHighlight;
    }

    .co2e-guessRaintrees {
      opacity: 0;
    }

    .hero-btn,
    .input-guess-raintrees {
      max-width: 115px;
      margin-top: 0.5rem;
      white-space: nowrap;
      text-align: center;
    }

    .app-form {
      form {
        padding: 0;

        .field-note {
          text-shadow: $textHighlight;
        }
      }

      .lm--formItem {
        &--inline {
          justify-content: center;
        }

        &-control {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
        }
      }
    }
  }

  .hero-gfx {
    display: block;
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &__bg {
      width: 100%;
      height: calc(100% - 200px);

      position: relative;
      z-index: 1;

      .gfx-bg-1 {
        position: absolute;
        bottom: -3%;
        left: 39%;
        transform: translateX(-100%);
        z-index: 5;
      }
      .gfx-bg-2 {
        position: absolute;
        bottom: calc(0% - 60px);
        right: 36%;
        transform: translateX(100%);
        z-index: 5;
      }

      .gfx-airplane {
        position: absolute;
        top: 0%;
        left: 0%;
        transform-origin: left center;
        will-change: transform;
        transform: translate(calc(var(--airplaneX) * 1px), calc((var(--airplaneY) + 100) * 1px));
        animation: fly 20s linear 2s infinite;
        z-index: 2;
      }

      .gfx-skycloud, [class$='-cloud'] {
        position: absolute;
        top: calc(var(--cloudY) * 1px);
        left: calc(var(--cloudX) * 1px);
        z-index: 3;

        &.qnnre-cloud1 {
          animation: cloudMovement 50s linear infinite;
        }
        &.qnnre-cloud2 {
          animation: cloudMovement 50s linear 2s infinite;
        }
        &.qnnre-cloud3 {
          animation: cloudMovement 50s linear 3s infinite;
        }
      }
    }
    &__fg {
      width: 100%;
      height: 30%;
      max-height: 200px;
      position: absolute;
      z-index: 5;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #ffffff;
      
      .good-side, .bad-side {
        display: block;
        position: absolute;
        width: 50%;
        height: 100%;
      }

      .good-side {
        top: 0;
        left: 50%;
        transform: translateX(-100%);
        z-index: 10;
        .gfx-grass1 {
          position: absolute;
          top: 30%;
          right: 250px;
          transform: translateY(-50%);
          width: 340px;
          .gfx-grass1-leaf2 {
            transform-origin: left bottom;
            animation: grassWindy1 12s cubic-bezier(.45,1.38,.49,-0.68) infinite;
          }
          .gfx-grass1-leaf1 {
            transform-origin: right bottom;
            animation: grassWindy2 12s cubic-bezier(.45,1.38,.49,-0.68) infinite;
          }
          .gfx-grass1-g1 {
            .gfx-grass1-leaf1, .gfx-grass1-leaf2 {
              animation-delay: 0.5s;
            }
          }
          .gfx-grass1-g2 {
            .gfx-grass1-leaf1, .gfx-grass1-leaf2 {
              animation-delay: 1s;
            }
          }
          .gfx-grass1-g3 {
            .gfx-grass1-leaf1, .gfx-grass1-leaf2 {
              animation-delay: 2s;
            }
          }
        }
        .gfx-biker {
          position: absolute;
          top: -15%;
          right: 100%;
          transform-origin: center bottom;
          animation: biker 5s ease-in-out forwards;
          .gfx-biker-wheel_back {
            transform-origin: center center;
            transform-box: fill-box;
            animation: bikerWheel 1s linear;
            animation-iteration-count: 5;
          }
          .gfx-biker-wheel_front {
            transform-origin: center center;
            transform-box: fill-box;
            animation: bikerWheel 1s linear;
            animation-iteration-count: 5;
          }
        }
      }

      .gfx-treehero {
        opacity: 0;
      }

      .gfx-co2logo { 
        opacity: 0;
      }

      .bad-side {
        top: 0;
        right: 50%;
        transform: translateX(100%);
        .gfx-wastesite {
          position: absolute;
          z-index: 1;
          top: -40px;
          left: 450px;
        }
        .gfx-drumcans {
          position: absolute;
          z-index: 2;
          top: -30px;
          left: 580px;
        }
        .gfx-stumpbranches {
          position: absolute;
          z-index: 3;
          top: 30%;
          left: 300px;
          transform: translateY(-50%);
        }
      }
    } 
  }
}

.hero-tree {
  display: block;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%) scale(0);
  transform-origin: 50% 100%;

  .gfx-featpodium {
    width: 408px;
    height: 96px;
    max-height: initial;
    position: relative;
    [class$='podium-platform'] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    [class$='podium_feat'] {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%) translateY(-80%);
      z-index: 3;
      line-height: 0;
    }
    [class$='bush1'] {
      position: absolute;
      z-index: 5;
      left: 10%;
      transform: scale(1.3);
    }
    [class$='bush2'] {
      position: absolute;
      z-index: 4;
      top: 2px;
      right: 5%;
      transform: scale(1.1);
    }
  }
  .gfx-treehero {
    position: absolute;
    bottom: 5px;
    left: 70%;
    z-index: 3;
    transform: translateX(-50%);
    width: 550px;
    height: 380px;
    transform-origin: 50% 100%;

    #gfx-treehero-canopy {
      transform-origin: center bottom;
      will-change: transform;
      animation: canopyWindy 20s cubic-bezier(.45,1.38,.49,-0.68) infinite;
    }
    #gfx-treehero-trunk {
      transform-origin: center bottom;
      will-change: transform;
      animation: trunkWindy 20s cubic-bezier(.45,1.38,.49,-0.68) infinite;
    }
  }
  .gfx-co2logo {
    position: relative;
    z-index: 5;
    transform-origin: 50% 100%;
    .gfx-co2logo-shadow {
      transform-origin: center bottom;
      will-change: transform;
      animation: canopyShadowWindy 20s cubic-bezier(.45,1.38,.49,-0.68) infinite;
    }
  }
}

.warn-mascot {
  width: 100%;
  height: 90px;
  .sky {
    height: 100vh;
    width: 100%;
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
  .gfx-skycloud, [class$='-cloud'] {
    position: absolute;
    top: calc(var(--cloudY) * 1px);
    left: calc(var(--cloudX) * 1px);
    z-index: 3;

    &.qnnre-cloud1 {
      will-change: transform;
      animation: cloudMovement 50s linear infinite;
    }
    &.qnnre-cloud2 {
      will-change: transform;
      animation: cloudMovement 50s linear 2s infinite;
    }
    &.qnnre-cloud3 {
      will-change: transform;
      animation: cloudMovement 50s linear 3s infinite;
    }
  }
  .hero-tree {
    position: relative;
    top: auto;
    left: auto;
    transform: translateX(0) translateY(0) scale(0.7);
    z-index: 2;
  }
}
