.app-welcome {
    display: block;
    width: 60.29vw;
    padding-right: 12vw;
    position: relative;

    &__title {
        font-size: 6rem;
        font-weight: 500;
        margin-bottom: 0.1em;
        line-height: 1.2;
    }

    &__subtitle {
        font-size: 2rem;
    }

    &__cta {
        display: inline-flex;
        width: 14.5vw;
        height: 14.5vw;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #00B0B2;
        color: #ffffff;
        border-radius: 50%;
        font-size: 2.3rem;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%) scale(1);
        transform-origin: center center;
        transition: all .5s ease;
        border: 2px solid #fff;
        box-shadow: 0 0 0px 8px #00B0B2;
        -webkit-tap-highlight-color: transparent;

        &:focus {
            outline: none;
        }

        &:active {
            transform: translateY(-50%) scale(1.1);
        }
    }
}
