@mixin bg-generic {
  background: #ffffff;
  background: -moz-linear-gradient(top, #aeeaeb 0%, #ffffff 100%);
  background: -webkit-linear-gradient(top, #aeeaeb 0%, #ffffff 100%);
  background: linear-gradient(to bottom, #aeeaeb 0%, #ffffff 100%);
}

@mixin bg-gradient {
  background: #26a2a7;
  background: -moz-linear-gradient(left, #26a2a7 0%, #173761 100%);
  background: -webkit-linear-gradient(left, #26a2a7 0%, #173761 100%);
  background: linear-gradient(to right, #26a2a7 0%, #173761 100%);
}

@mixin bg-gradient-vert {
  background: #173761; /* Old browsers */
  background: -moz-linear-gradient(top, #173761 0%, #26a2a7 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #173761 0%, #26a2a7 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #173761 0%, #26a2a7 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

@mixin bg-gradient-sky {
  background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, #F8FCFF 74%, #F8FCFF 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,#F8FCFF 74%,#F8FCFF 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,#F8FCFF 74%,#F8FCFF 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00F8FCFF', endColorstr='#F8FCFF',GradientType=0 ); /* IE6-9 */
}

@mixin bg-gradient-fog {
  background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, #FFFFFF 74%, #FFFFFF 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,#FFFFFF 74%,#FFFFFF 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,#FFFFFF 74%,#FFFFFF 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00FFFFFF', endColorstr='#FFFFFF',GradientType=0 ); /* IE6-9 */
}

@mixin field-error {
  &:first-of-type:focus {
    border-color: $color-red;
  }

  &:first-of-type {
    border-color: $color-red;
  }
}

@mixin field-modified {
  &:focus {
    border-color: $color-orange;
  }
  border-color: $color-orange;
}
