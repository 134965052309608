.app-modal {
  &.TnC-modal, &.Consent-modal, &.PDPA-modal {
    width: 100%;
  }

  &.app-confirmation_dialog {
    padding: 2rem;
    min-width: 480px;
    max-width: 600px;

    @media only screen and (min-width: 768px) and (max-height: 662px) {
      max-height: 600px;
      overflow-y: scroll;
      display: block;
    }

    & > div {
      &:first-child {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
      }
    }

    .alert-icon {
      min-height: 100px;
      min-width: 30px;
      margin-bottom: 0.5rem;

      img {
        width: 100%;
      }
    }

    h1 {
      margin-bottom: 0.5rem;
    }

    h4 {
      font-weight: 400;
      text-align: center;
    }

    .modal-ctas {
      margin-top: 2rem;

      button {
        padding: 0.5625em 1em;
      }
    }
  }

  &.app-reminder_dialog {
    padding: 2rem;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    max-width: 772px;

    .content {
      padding-top: 2rem;
    }

    h3 {
      font-size: 1.5rem;
    }

    .modal-ctas {
      width: 100%;
      justify-content: center;

      button {
        min-width: 175px;
        text-align: center;
        justify-content: center;
        align-items: center;
      }
    }

    .app-accordion {
      margin-bottom: 1rem;
    }
  }

  .content {
    display: block;
    width: 100%;
  }
}
