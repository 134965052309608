.page-questionnaire {
  .app-qnnre__category {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    max-width: calc(720px + 2rem);
    max-height: 100%;
    overflow: hidden;
    position: relative;
    z-index: 10;
    padding-top: 0.5rem;

    &.type-web{
      &:before {
      content: "";
      display: block;
      width: 100%;
      height: 70px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      z-index: 2;
      pointer-events: none;
      @include bg-gradient-sky;
      }
    }

    &Container {
      position: relative;
      width: 100%;
      max-height: 100%;
      overflow-y: auto;
      padding: 0 1rem;
      height: calc(100% + 1px);
    }
    &.app-qnnre__home-mobile,
    &.app-qnnre__energy,
    &.app-qnnre__food,
    &.app-qnnre__spending,
    &.app-qnnre__recycle,
    &.app-qnnre__commute,
    &.app-qnnre__travel {
      .app-qnnre__fun-facts {
      max-width: 675px;
      position: relative;
      background: #ffffff;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 1rem;
      margin: -1rem 1rem 3.5rem;
      border-radius: 5px;
      img {
        opacity: 1;
      }
      &-fact {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-basis: 90%;
        margin-left: 1rem;

        h5 {
          padding-bottom: 0.5rem;
          font-weight: 500;
          font-size: var(--font-size-4);
        }

        p {
          text-align: left;
        }
      }
    }
    
    .app-form {
      form {
        padding: 2rem 0 0;
      }
    } 
  }   
}

  .app-qnnre__desc {
    display: block;
    & + .app-qnnre__desc {
      margin-top: 0.5rem;
    }
  }

  .app-qnnre__gfx {
    width: 100%;
    height: 30%;
    max-height: 285px;
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 11;

    .app-qnnre-counter {
      display: flex;

      width: 330px;
      height: 85px;
      position: absolute;
      z-index: 1;
      bottom: calc(25% + 40px);
      left: 50%;
      transform: translateX(-26%);
      background-color: #ffffff;
      box-shadow: 0px 2px 8px #E7ECEF;
      border-radius: 5px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding-left: 3rem;
      span {
        font-size: 1.25rem;
        margin: 0;
      }
      .app-qnnre__title {
        color: #4F5A60;
      }
      .app-qnnre__value {
        color: #FF9D5A;
      }
    }
  }

  .app-qnnre__bg {
    display: block;
    width: 100%;
    height: 100%;
    max-height: initial;
    z-index: 2;
  }

  .gfx-skycloud, [class$='-cloud'] {
    position: absolute;
    top: calc(var(--cloudY) * 1px);
    left: calc(var(--cloudX) * 1px);
    z-index: 3;

    &.gfx-cloud1 {
      animation: cloudMovement 50s linear infinite;
    }
    &.gfx-cloud2 {
      animation: cloudMovement 50s linear 2s infinite;
    }
    &.gfx-cloud3 {
      animation: cloudMovement 50s linear 3s infinite;
    }
  }

  .field-choose {
    .choices {
      .choose {
        margin: 0.4rem;
        max-width: calc(50% - 0.8rem);
        min-width: calc(50% - 0.8rem);
        flex-basis: calc(50% - 0.8rem);
      }
    }
  }
}
