.form-survey {
  .field-cta {
    padding-left: 0;
    text-align: center;
    justify-content: center;
    .app-button {
      min-width: 375px;
    }
  }
}

.surveylist {
  counter-reset: steps-counter;
  display: block;
  text-align: left;
  &-item {
    counter-increment: steps-counter;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    .question {
      font-size: 1rem;
      font-weight: 500;
      position: relative;
      &:before {
        content: counter(steps-counter)'.';
        font-size: inherit;
        font-weight: inherit;
        margin-right: 0.5rem;
      }
    }
    .answer {
      display: flex;
      margin: 1rem 0;
      &.row {
        .field-select {
          margin: 0 0.875rem !important;
          flex-basis: 50%;
          max-width: calc(50% - (0.875rem * 2));
          flex-grow: 1;
          .control {
            flex-basis: 100%;
            max-width: 100%;
            margin: 0;
          }
        }

      }
    }
    .field-choose, .field-select, .field-input {
      .label {
        display: none;
      }
    }
    .field-choose {
      .switches {
        & > div {
          margin: 0 0.875rem !important;
          flex-basis: 25%;
          max-width: calc(25% - (0.875rem * 2)) !important;
        }
      }
    }
    .field-select {
      .control {
        margin: 0.2rem 0.875rem;
        flex-basis: 50%;
        max-width: calc(50% - (0.875rem * 2));
        flex-grow: 1;
      }
    }
    .field-input {
      margin: 0 0.875rem !important;
      flex-basis: 50%;
      max-width: calc(50% - (0.875rem * 2));
      flex-grow: 1;
    }
  }
}
