@keyframes cloudMovement {
  0% {
    transform: translateX(0px) translateZ(0);
  }
  100% {
    transform: translateX(100vw) translateZ(0);
  }
}

@keyframes fly {
  0% {
    transform: translate(calc(var(--airplaneX) * 1px), calc((var(--airplaneY) + 100) * 1px)) translateZ(0);
    
  }
  50% {
    transform: translate(calc(100vw + 200px), calc((35vh - 100%) - 285px)) translateZ(0);
  }
  100% {
    transform: translate(calc(100vw + 200px), calc((35vh - 100%) - 285px)) translateZ(0);
  }
}

@keyframes flyM {
  0% {
    transform: translate(calc(var(--airplaneX) * 1px - 200px), calc((var(--airplaneY) + 100) * 1px)) translateZ(0);
    
  }
  50% {
    transform: translate(calc(100vw + 200px), calc((35vh - 100%) - 285px)) translateZ(0);
  }
  100% {
    transform: translate(calc(100vw + 200px), calc((35vh - 100%) - 285px)) translateZ(0);
  }
}

@keyframes canopyWindy {
  0% {
    transform: scaleY(1) rotate(0deg) translateX(0px) translateY(0px) translateZ(0);
  }

  50% {
    transform: scaleY(1.1) rotate(5deg) translateX(20px) translateY(0px) translateZ(0);
  }

  100% {
    transform: scaleY(1) rotate(0deg) translateX(0px) translateY(0px) translateZ(0);
  }
}

@keyframes canopyShadowWindy {
  0% {
    transform: scaleY(1) rotate(0deg) translateX(0px) translateY(0px) translateZ(0);
  }

  50% {
    transform: scaleY(1.1) rotate(5deg) translateX(20px) translateY(0px) translateZ(0);
  }

  100% {
    transform: scaleY(1) rotate(0deg) translateX(0px) translateY(0px) translateZ(0);
  }
}

@keyframes trunkWindy {
  0% {
    transform: skewX(0deg) translateZ(0);
  }

  50% {
    transform: skewX(-5deg) translateZ(0);
  }

  100% {
    transform: skewX(0deg) translateZ(0);
  }
}

@keyframes grassWindy1 {
  0% {
    transform: skewX(0deg) translateZ(0);
  }

  50% {
    transform: skewX(-15deg) translateZ(0);
  }

  100% {
    transform: skewX(0deg) translateZ(0);
  }
}

@keyframes grassWindy2 {
  0% {
    transform: skewX(0deg) translateZ(0);
  }

  50% {
    transform: skewX(-15deg) translateZ(0);
  }

  100% {
    transform: skewX(0deg) translateZ(0);
  }
}

@keyframes biker {
  0% {
    transform: translateX(-20vw) translateZ(0);
  }
  100% {
    transform: translateX(calc(35vw - 50%)) translateZ(0);
  }
}

@keyframes bikerForM {
  0% {
    transform: translateX(-20vw) scale(0.45) translateZ(0);
  }
  100% {
    transform: translateX(calc(35vw)) scale(0.45) translateZ(0);
  }
}

@keyframes bikerWheel {
  0% {
    transform: rotate(0deg) translateZ(0);
  }

  100% {
    transform: rotate(360deg) translateZ(0);
  }
}

@keyframes co2cloudAffectTheTree {
  0% {
    transform: translateX(-50%) translateY(-80%) translateZ(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-50%) translateY(-30%) translateZ(0);
    opacity: 0;
  }

}

@keyframes affectTree {
  0% {
    filter: grayscale(0);
  }

  100% {
    filter: grayscale(1);
  }
}

@keyframes popCategory {
  0% {
    transform: scale(0) translateY(0) translateX(0) rotate(0deg) translateZ(0);
  }
  50% {
    transform: scale(1) translateY(-8%) translateX(2%) rotate(-3deg) translateZ(0);
  }

  100% {
    transform: scale(1) translateY(0%) translateX(0) rotate(0deg) translateZ(0);
  }
}

@keyframes popCategoryTransport {
  0% {
    transform: scale(0) translateY(0) translateX(0) rotate(0deg) translateZ(0);
  }
  50% {
    transform: scale(1) translateY(-8%) translateX(2%) rotate(-3deg) translateZ(0);
  }

  100% {
    transform: scale(1) translateY(5px) translateX(0) rotate(0deg) translateZ(0);
  }
}

@keyframes pulse {
	0% {
    transform: scale(0.95) translateZ(0);
    opacity: 0;
	}

	70% {
    transform: scale(1) translateZ(0);
    opacity: 1;
	}

	100% {
    transform: scale(0.95) translateZ(0);
    opacity: 0;
	}
}

@keyframes appear {
  0% {
    opacity: 0;
	}
  100% {
    opacity: 1;
	}
}

@keyframes panningLeft {
  0% {
    transform: translateX(0%) translateZ(0);
  }
  50% {
    transform: translateX(-2%) translateZ(0);
  }
  100% {
    transform: translateX(0%) translateZ(0);
	}
}

@keyframes panningRight {
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(2%);
  }
  100% {
    transform: translateX(0%);
	}
}

@keyframes flaming {
  0% {
    transform: translateY(0%) translateZ(0);
  }
  50% {
    transform: translateY(-3%) translateZ(0);
  }
  100% {
    transform: translateY(0%) translateZ(0);
  }
}

@keyframes belching1 {
  0% {
    opacity: 0.9;
    transform: scale(1) translate(0, 0) translateZ(0);
  }
  20% {
    opacity: 0.9;
    transform: scale(1.6) translate(-10px, -20px) translateZ(0);
  }
  60% {
    opacity: 0.9;
    transform: scale(2.2) translate(-20px, -60px) translateZ(0);
  }
  80% {
    opacity: 0.7;
    transform: scale(1.8) translate(10px, -80px) translateZ(0);
  }
  100% {
    opacity: 0;
    transform: scale(2.2) translate(30px, -70px) translateZ(0);
  }
}

@keyframes belching2 {
  0% {
    opacity: 0.9;
    transform: scale(1) translate(0, 0) translateZ(0);
  }
  20% {
    opacity: 0.9;
    transform: scale(1.5) translate(10px, -50px) translateZ(0);
  }
  60% {
    opacity: 0.9;
    transform: scale(2.5) translate(35px, -60px) translateZ(0);
  }
  80% {
    opacity: 0.7;
    transform: scale(2.4) translate(40px, -70px) translateZ(0);
  }
  100% {
    opacity: 0;
    transform: scale(2) translate(55px, -80px) translateZ(0);
  }
}

@keyframes belching1IE {
  0% {
    opacity: 0.9;
    transform: translate(0, 0) translateZ(0);
  }
  20% {
    opacity: 0.9;
    transform: translate(-10px, -20px) translateZ(0);
  }
  60% {
    opacity: 0.9;
    transform: translate(-20px, -60px) translateZ(0);
  }
  80% {
    opacity: 0.7;
    transform: translate(10px, -80px) translateZ(0);
  }
  100% {
    opacity: 0;
    transform: translate(30px, -70px) translateZ(0);
  }
}

@keyframes belching2IE {
  0% {
    opacity: 0.9;
    transform: translate(0, 0) translateZ(0);
  }
  20% {
    opacity: 0.9;
    transform: translate(10px, -50px) translateZ(0);
  }
  60% {
    opacity: 0.9;
    transform: translate(35px, -60px) translateZ(0);
  }
  80% {
    opacity: 0.7;
    transform: translate(40px, -70px) translateZ(0);
  }
  100% {
    opacity: 0;
    transform: translate(55px, -80px) translateZ(0);
  }
}

@keyframes cloudAppear1 {
  0% {
    opacity: 0;
    transform: translateX(10%) translateZ(0);
  }
  100% {
    opacity: 1;
    transform: translateX(0%) translateZ(0);
  }
}

@keyframes cloudAppear2 {
  0% {
    opacity: 0;
    transform: translateX(-10%) translateZ(0);
  }
  100% {
    opacity: 1;
    transform: translateX(0%) translateZ(0);
  }
}

@keyframes totalAppear {
  0% {
    opacity: 0;
    transform: translateY(5%) translateZ(0);
  }
  100% {
    opacity: 1;
    transform: translateY(0%) translateZ(0);
  }
}

@keyframes unitAppear {
  0% {
    opacity: 0;
    transform: translateX(-5%) translateZ(0);
  }
  100% {
    opacity: 1;
    transform: translateX(0%) translateZ(0);
  }
}

@keyframes totalDescriptionAppear {
  0% {
    opacity: 0;
    transform: translateX(-50%) translateY(110%) translateZ(0);
  }
  100% {
    opacity: 1;
    transform: translateX(-50%) translateY(120%) translateZ(0);
  }
}

@keyframes totalDescriptionAppearM {
  0% {
    opacity: 0;
    transform: translateX(-50%) translateY(-10%) translateZ(0);
  }
  100% {
    opacity: 1;
    transform: translateX(-50%) translateY(0%) translateZ(0);
  }
}

@keyframes chartBdAppear {
  0% {
    opacity: 0;
    transform: translateY(10%) translateZ(0);
  }
  100% {
    opacity: 1;
    transform: translateY(0%) translateZ(0);
  }
}

@keyframes appear {
  0% {
    opacity: 0;
    transform: translateY(10%) translateZ(0);
  }
  100% {
    opacity: 1;
    transform: translateY(0%) translateZ(0);
  }
}

@keyframes heroAppear {
  0% {
    opacity: 1;
    transform: translateX(-50%) translateY(-50%) scale(0.3) rotate(0deg) translateZ(0);
  }
  60% {
    opacity: 1;
    transform: translateX(-50%) translateY(-110%) scale(1.1) rotate(10deg) translateZ(0);
  }
  100% {
    opacity: 1;
    transform: translateX(-50%) translateY(-50%) scale(1) rotate(0deg) translateZ(0); 
  }
}

@keyframes heroAppearT {
  0% {
    opacity: 1;
    transform: translateX(-50%) translateY(-50%) scale(0.3) rotate(0deg) translateZ(0);
  }
  60% {
    opacity: 1;
    transform: translateX(-50%) translateY(-110%) scale(1.4) rotate(10deg) translateZ(0);
  }
  100% {
    opacity: 1;
    transform: translateX(-50%) translateY(-50%) scale(1.2) rotate(0deg) translateZ(0); 
  }
}

@keyframes heroAppearM {
  0% {
    opacity: 0;
    transform: translateX(-50%) translateY(-50%) scale(0) rotate(0deg) translateZ(0);
  }
  60% {
    opacity: 1;
    transform: translateX(-50%) translateY(-110%) scale(0.6) rotate(10deg) translateZ(0);
  }
  100% {
    opacity: 1;
    transform: translateX(-50%) translateY(-50%) scale(0.45) rotate(0deg) translateZ(0); 
  }
}

@keyframes heroTreeAppear {
  0% {
    opacity: 1;
    transform: translateX(-50%) translateY(0%) scale(0) rotate(0deg) translateZ(0);
  }
  50% {
    opacity: 1;
    transform: translateX(-50%) translateY(-5%) scale(0.9) rotate(5deg) translateZ(0);
  }
  100% {
    opacity: 1;
    transform: translateX(-50%) translateY(0%) scale(1) rotate(0deg) translateZ(0);
  }
}

@keyframes heroCO2Appear {
  0% {
    opacity: 1;
    transform: translateY(0%) scale(0) rotate(0deg) translateZ(0);
  }
  50% {
    opacity: 1;
    transform: translateY(-20%) scale(1) rotate(-10deg) translateZ(0);
  }
  100% {
    opacity: 1;
    transform: translateY(0%) scale(1) rotate(0deg) translateZ(0);
  }
}
