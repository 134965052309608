.gfx-sky {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
  .gfx-skycloud {
    position: absolute;
    z-index: 2;
    top: calc(var(--cloudY) * 1px);
    left: calc(var(--cloudX) * 1px);
  }
}
