.responsive-warn, .browser-warn {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;

  .warn {
    display: none;
    max-width: 600px;
    padding: 2rem;
  }

  .warn-message {
    line-height: 2;
    color: #00b0b2;
    margin: 0;
  }
}

.browser-warn {
  z-index: 999;
  display: flex;
  overflow: hidden;
  .sky {
    background-color: #F8FCFF;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    .gfx-skycloud {
      position: absolute;
      top: calc(var(--cloudY) * 1px);
      left: calc(var(--cloudX) * 1px);
    }
  }
  .warn-message {
    position: relative;
    z-index: 10;
    font-size: 1.5rem;
    max-width: 700px;
    width: 100%;
    padding: 1rem;
    text-align: center;
    color: var(--color-text);
  }
  .warn-mascot {
    position: absolute;
    bottom: 0;
    left: 50%;
    z-index: 9;
    height: auto;
    width: auto;
    transform: translateX(-50%);
  }
}

@mixin holaMessage {
  .app-main {
    display: none;
  }
  .responsive-warn {
    display: flex;
    visibility: visible;
    .warn-mobile {
      width: 70%;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
    .warn-mascot {
      position: absolute;
      bottom: 0;
      right: 0;
      left: auto;
      width: 100%;
      height: 100%;
    }
  }
}

// Desktop
@media only screen and (min-width: 1025px) {
  :root {
    font-size: 16px;
  }

  .app-modal {
    &.app-confirmation_dialog {
      max-width: 550px;
    }
  }

  .page-menu {
    .app-cardselection {
      bottom: 45%;
      transform: translateX(-50%) translateY(50%);
    }
  }
}

// Mobile
@media only screen and (max-width: 736px) {

  .app-header {
    z-index: 2000;
    height: auto !important;
    .logo {
      width: 75px !important;
    }
  }

  .app-main {
    display: block;
  }
  .app-modal {
    h2 {
      font-size: 1.2rem;
    }
    &.app-confirmation_dialog {
      min-width: initial;
      width: 95%;
      max-width: 480px;
      max-height: 90%;
      overflow: scroll;
      align-items: flex-start;
      justify-content: flex-start;
      padding: 1.8rem 1.2rem;
      .modal-ctas {
        margin-top: 1rem;
      }
    }
  }

  .responsive-warn {
    display: none;
    visibility: hidden;
    overflow: hidden;
    background-color: #F8FCFF;

    .warn-mascot {
      position: absolute;
      bottom: 0;
      left: 0;
    }

    .warn-mobile {
      display: block;
      position: absolute;
      top: 20%;
      left: 0;
      padding: 1rem;
      text-align: center;
      font-size: 1.2rem;
      z-index: 10;
    }
  }

  .app-footer {
    font-size: 0.7rem;
  }
}

// Tablet
@media only screen and (min-width: 737px) and (max-width: 1100px) {
  .app-header {
    z-index: 2000;
    height: auto !important;
  }

  .responsive-warn {
    display: none;
    overflow: hidden;
    background-color: #F8FCFF;

    .warn-mascot {
      position: absolute;
      bottom: 0;
      left: 0;
      .hero-tree {
        transform: translateX(0) translateY(0) scale(1);
      }
    }

    .warn-mobile {
      display: block;
      position: absolute;
      top: 20%;
      left: 50%;
      padding: 1rem;
      text-align: center;
      font-size: 2rem;
      z-index: 10;
      width: 100%;
      max-width: 500px;
      transform: translateX(-50%);
    }
  }
}

@media only screen and (min-width: 737px) and (max-width: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
  body {
    overflow: initial;
  }
  .app-main {
    display: block;
  }

  .responsive-warn {
    display: none;
  }
}

@media only screen and (min-width: 737px) and (max-width: 1366px) {
  :root {
    font-size: 16px;
  }

  .page-menu {
    .app-cardselection {
      bottom: 45%;
      transform: translateX(-50%) translateY(50%);
    }
  }

  .app-modal {
    &.app-confirmation_dialog {
      max-width: 550px;
    }
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1366px) and (max-height: 1024px) and (orientation: landscape) {
  .page-menu {
    .app-cardselection {
      bottom: 45%;
      transform: translateX(-50%) translateY(50%);
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 800px) {
  .page-results {
    .app-chart_detail {
      padding-left: 1.5rem;
    }
    .app-chart_detail__label {
      height: 65px;
      &:before {
        position: absolute;
        top: 8px;
        right: 100%;
        margin-right: 1rem;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .page-results {
    .results-tips .app-article.is-crosswise .app-article__content {
      align-items: center;
      text-align: center;
    }
  } 
}

// screens with 1367px and above
@media only screen and (min-width: 1367px) {
  .app-page {
    &.page-saleskit--configurator {
      .app-page__container {
        padding: calc(658px + 20px) 0 80px 0;
      }
    }
  }
}

// SPECIAL HANDLERS
// Screens with height 560px and lower
@media only screen and (max-height: 560px) {
  .page-questionnaire {
    // Hide the gfx
    .app-qnnre__gfx {
      visibility: hidden;
      opacity: 0;
      z-index: -1;
    }
  }
  .cui-progress {
    display: none;
  }
  .__questionnaire {
    .app-page {
      &.page-questionnaire {
        .app-page__container {
          padding-bottom: 1rem !important;
        }
      }
    }
    .app-footer {
      visibility: hidden;
      opacity: 0;
      z-index: -1;
    }
  }
}

// Screens with shorter height of 601px upto 767px
@media only screen and (min-height: 601px) and (max-height: 767px) {
  .app-page {
    &.page-questionnaire {
      .app-page__container {
        padding-top: 1.25rem;
        padding-bottom: 20vh;
      }
      .app-qnnre-counter {
        bottom: 60%;
        transform-origin: 0% 100%;
        transform: scale(0.8) translateX(-30%);
      }
    }
  }

  .page-questionnaire {
    .app-qnnre__gfx {
      height: 20vh;
    }
    .gfx-buildings {
      transition: all .5s ease;
      top: -120px;
    }
    .gfx-othertrees {
      transition: all .5s ease;
      top: -70px;
    }
    .gfx-trees {
      .gfx-trees__section {
        transition: all .5s ease;
        &#trees__section1 {
          transform: scale(0.85) translateY(30px) translateX(-25px);
        }
        &#trees__section2 {
          transform: scale(0.85) translateY(30px) translateX(25px);
        }
      }
    }
    .gfx-podium {
      transition: all .5s ease;
      bottom: 20%;
      transform: scale(0.8);
    }
  }
  
  .app-footer {
    padding: 0.5rem 1rem;
  }
}

// Screens with less 320px for width
@media only screen and (max-width: 319px) {
  @include holaMessage;
}

// Screens with 250px and less for height
@media only screen and (max-height: 250px) {
  @include holaMessage;
}

