.floormap-viewer {
  display: block;
  width: 100%;
  position: relative;
  background-color: $floormapContainerBG;

  .viewer {
    display: flex;
    padding: 2rem;
    padding-left: 0;
    width: 100%;
    height: 100%;
    max-width: 1366px;
    margin: 0 auto;
    border-bottom: 1px solid rgba(231, 235, 238, 1);
    justify-content: center;
    align-items: center;

    .viewer-content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      text-align: left;
      flex-basis: 33%;
      padding: 2rem 1.5rem;
      padding-right: 0;

      h1 {
        margin-bottom: 0;
      }

      span {
        font-size: 0.75rem;
      }

      .field-group {
        width: 100%;

        header {
          display: none;
        }
      }

      .field-choose {
        width: 100%;

        .control {
          width: 100%;
        }

        .switches {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          .lm--switch {
            flex-grow: 1;
            margin: 0.5rem;
            flex-basis: calc(50% - 0.5rem);
            max-width: calc(50% - 0.5rem);

            &:nth-child(odd) {
              margin-left: 0;
            }

            &:nth-child(even) {
              margin-right: 0;
            }

            .lm--switch-input {
              &:checked {
                & + .lm--switch-label {
                  &:before {
                    margin-left: 0;
                    margin-bottom: 0.1rem;
                  }
                }
              }
            }

            .lm--switch-label {
              padding: 0.5625rem 1rem;
              width: 100%;
            }
          }
        }
      }

      .lm--formItem {
        display: flex;
        flex-direction: column;

        .label {
          font-size: 1.5rem;
          font-weight: 500;
          line-height: 1.25;
        }
      }
    }

    .floormap {
      flex-basis: 66%;
      height: 100%;
      display: block;
      position: relative;
      padding: 0.5rem;

      .floormap-overlay {
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 2;
        padding: 0.5rem;

        svg, img {
          width: 100%;
          height: 100%;
          overflow: none;
        }

        .floormap-bubble {
          g {
            transform-origin: center center;
          }
        }
      }

      .floormap-base {
        height: 100%;
        opacity: 0.65;
        line-height: 0;
        background-size: cover;
        background-position: center center;
        background-repeat: no-repeat;
        mix-blend-mode: multiply;

        svg, img {
          height: 100%;
          width: 100%;
          opacity: 0;
        }
      }
    }
  }

  .viewer-notes {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    width: 25%;
    font-size: 0.65rem;
  }
}

@keyframes showBubble {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}
