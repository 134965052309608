.plan-item {
  display: flex;
  width: 100%;

  &_section {
    display: flex;
    padding: 0.875rem;
    border-bottom: 1px solid rgba(231, 236, 239, 1);
    flex-grow: 2;

    &:last-child {
      border-bottom: 0;
      flex-grow: 1;
    }
  }

  &.row {
    .plan-item_section {
      flex-grow: 1;
      border-bottom: 0;
      border-right: 1px solid rgba(231, 236, 239, 1);

      &:last-child {
        flex-grow: 2;
        padding-left: 0;
        padding-right: 0;
        padding: 0;

        .item-node {
          padding: 0.5rem 1rem 0.5rem;
          height: 50%;
        }
      }
    }
  }

  h2 {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.25rem;
    padding: 0 0.5rem 0.5rem;

    svg {
      margin-right: 0.75rem;
    }
  }

  .item-node {
    width: 100%;
    border-bottom: 1px solid rgba(231, 236, 239, 1);

    &:last-child {
      border-bottom: 0;
    }
  }
}
