.app-backdrop {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba($space-colour, 0.4);
}

.app-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  min-width: 320px;
  max-width: 948px;
  min-height: 200px;
  background-color: #fff;
  outline: none !important;
  padding: 1rem;
  border-radius: 0.625rem;
  box-shadow: 0 2px 20px 1px rgba(#e7ecef, 0.6);

  &.modal-terms {
    width: 60vw;
    height: 70vh;
    overflow: hidden;

    .terms-container {
      width: 100%;
      height: 100%;
      overflow-y: scroll;
    }

    .terms-and-conditions {
      & > div {
        margin-bottom: 1rem;
      }
    }

    .extContents {
      & > div {
        margin-bottom: 0.5rem;
      }
    }
  }

  .modal-close-btn {
    width: 160px;
    cursor: pointer;
    margin: 1.5rem auto 0 auto;
    display: block;
  }

  .modal-close {
    display: inline-block;
    position: absolute;
    outline: none !important;
    top: 1rem;
    right: 1rem;
    z-index: 100;
    cursor: pointer;
    transform: scale(1.5);
  }

  .modal-ctas {
    display: flex;
    flex-direction: row;
    width: 100%;
    margin-top: rem(20);
    align-items: center;
    justify-content: center;

    button {
      margin-right: rem(10);
      outline: none !important;
      flex-basis: 50%;
      font-weight: 600;
      text-align: center;
      justify-content: center;
      max-width: 160px;
      min-width: 160px;

      &:only-child {
        flex-basis: 100%;
      }

      &:last-child {
        margin-right: 0;
      }

      * {
        pointer-events: none;
      }
    }
  }

  .modal-footnote {
    display: block;
    width: 100%;

    .fn-item {
      display: flex;
      flex-direction: row;
      padding-bottom: 0.4rem;
      justify-content: center;
      align-items: center;

      &:last-child {
        padding-bottom: 0;
      }

      .circle {
        display: inline-block;
        margin-right: 0.5rem;
        border-radius: 100%;
        width: 5px;
        height: 5px;
      }

      span {
        display: inline-block;
        vertical-align: middle;
        font-size: 0.7rem;
      }
    }
  }

  h2 {
    font-weight: 500;
    line-height: 1.3;
    text-align: center;
    font-size: 2rem;
  }

  h3 {
    margin-bottom: 0.4em;
    margin-top: 0.4em;
    text-align: center;
  }

  .modal-arrow {
    display: inline-block;
    position: absolute;
    background-color: inherit;
    width: 20px;
    height: 20px;
    transform-origin: 50% 50%;
    transform: rotate(45deg) translate(20%, 50%);
    z-index: 1;
    border-bottom: 0;
    border-right: 0;
    box-shadow: -5px -5px 10px 1px rgba(0, 0, 0, 0.02);

    &.right_top {
      bottom: 100%;
      right: 20px;
    }

    &.left_top {
      bottom: 100%;
      left: 20px;
    }

    &.right_bottom {
      top: 100%;
      right: 20px;
    }

    &.left_bottom {
      top: 100%;
      left: 20px;
    }

    &.center_top {
      left: 50%;
      bottom: 100%;
      transform: rotate(45deg) translate(20%, 50%);
    }

    &.center_bottom {
      left: 50%;
      top: 100%;
      transform: rotate(45deg) translate(20%, 50%);
    }

    &.left_middle {
      left: 20px;
      bottom: 50%;
      transform: rotate(45deg) translate(20%, 50%);
    }

    &.right_middle {
      right: 20px;
      top: 50%;
      transform: rotate(45deg) translate(20%, 50%);
    }
  }

  .container {
    padding: 2rem 5.5rem 1rem;
    width: 100%;
    height: 100%;
    max-height: 80vh;
    overflow-y: scroll;

  }

  .pdpa-container {
    h2 {
      margin-bottom: 1.5rem;
    }

    h3 {
      font-weight: 500;
      font-size: 1rem;
      margin: 1rem 0;
    }

    p {
      margin: 1rem 0;

      b, strong {
        font-weight: 500;
      }
    }

    ul {
      list-style-position: inside;
    }
  }

  $itemNumberWidth: 50px;
  $itemTitleWidth: 150px;
  $nestedItemWidth: 40px;

  .tnc-container {
    padding: 2rem 3rem 1rem;

    & > ol {
      padding-right: 2rem;
    }

    ol {
      counter-reset: term-index;
      margin-top: 2rem;
      & > li {
        display: block;
        list-style: none;
        position: relative;
        width: 65%;
        margin: 0.5rem auto;
        text-align: justify;

        li {
          width: auto;
          &:before {
            width: auto;
            max-width: initial;
          }
          &:after {
            content: '';
            display: none;
          }
        }

        // Main Item Number
        &:before {
          content: counter(term-index)'.';
          display: inline-block;
          position: absolute;
          right: 100%;
          top: 3px;
          padding-right: 2rem;
          width: $itemNumberWidth;
          max-width: $itemNumberWidth;
          text-align: right;
          line-height: 1;
        }

        // Main Item Title
        &:after {
          content: attr(data-title);
          display: inline-block;
          position: absolute;
          left: 100%;
          top: 0px;
          width: $itemTitleWidth;
          max-width: $itemTitleWidth;
          padding-left: 2rem;
          text-align: left;
        }
      }

      li {
        counter-increment: term-index;
        font-size: 1rem;
      }

      ol {
        list-style-type: none;
        counter-reset: nested-term;
        margin-top: 1rem;

        li {
          counter-increment: nested-term;
          margin-left: $nestedItemWidth;
          margin-top: 1rem;

          &:before {
            content: counter(term-index)'.'counter(nested-term);
            position: absolute;
            right: 100%;
            width: $nestedItemWidth;
            max-width: $nestedItemWidth;
            text-align: left;
          }
        }

        ol {
          counter-reset: deep-nested-term;

          li {
            counter-increment: deep-nested-term;

            &:before {
              content: '('counter(deep-nested-term, lower-roman)')';
            }
          }
        }
      }
    }
  }

  .consent-container {
    h1 {
      text-align: center;
    }

    p {
      margin-top: 1rem;
    }

    ul {
      margin-top: 1rem;
      list-style: square inside;
      li {
        margin: 0.5rem 0;
      }
    }
  }
}
