// MOBILE styles

@media only screen and (min-width: 320px) and (max-width: 736px) {
  h1 {
    font-size: 1.5rem;
  }

  h2 {
    font-size: 1.3rem;
  }

  .app-header {
    padding: 1rem !important;
  }

  .app-footer {
    padding: 1rem;
  }

  .page-landing {
    .app-page__container {
      position: relative;
      height: initial;
      overflow: initial;
    }

    .section-blade {
      height: auto;
      min-height: initial;

      .blade-container {
        height: auto;
      }
    }

    #co2-landing-blade2 {
      height: auto;

      .blade-container {
        padding: 1rem;
      }

      .app-article {
        padding: 0;
      }

      .gfx-globecc {
        height: 250px;
      }
    }

    #co2-landing-blade3 {
      height: auto;
      padding: 2rem 0;
      padding-bottom: 4rem;
    }

    .featured-list {
      flex-direction: column;

      .animate {
        .app-article {
          .app-article__hero {
            opacity: 1;
          }

          .app-article__header {
            opacity: 1;
          }

          .app-article__content {
            opacity: 1;
          }
        }
      }
    }
  }

  .gfx-trees {
    transform: translateY(0%);

    &:after {
      content: '';
      width: 100%;
      height: 10vh;
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 10;

      @include bg-gradient-fog;
    }

    .gfx-trees__section {
      transform: translateY(-40%);
    }

    .gfx-treegroup {
      width: 100%;
      transform: translateX(calc(var(--treeGroupOffsetX) * 1%))
        translateY(calc(var(--treeGroupOffsetY) * 1.8%));
    }
  }

  .gfx-podium {
    left: 50%;
    transform-origin: 50% 100%;
    transform: scale(1) translateX(-50%);
  }

  .app-page {
    &.type-generic {
      .app-page__container {
        padding: 3.5rem 1rem;
      }
    }

    &.page-landing {
      min-height: initial;
    }

    &.page-questionnaire {
      min-height: initial;
      height: 100vh; // Fallback
      height: calc(var(--vhValue, 1vh) * 100);
      max-height: 100%; // Fallback
      max-height: calc(var(--vhValue, 1vh) * 100);

      .app-page__container {
        min-height: initial;
        padding-bottom: calc(15vh + 3rem);
        &.type-infinity {
          // padding-bottom: 3.5rem;
          overflow-y: scroll;
          display: block;
        }
      }
    }
  }

  .page-questionnaire {
    .app-qnnre__title {
      font-size: 1.2rem !important;
      font-weight: 600;
      margin-top: 0 !important;
    }

    .app-page__container {
      padding-bottom: calc(18vh + 100px);
      padding-right: 0 !important;
      padding-left: 0 !important;
      text-align: left;
      align-items: flex-start !important;

      & > h3 {
        display: block;
        width: 100%;
        font-size: 1rem;
        padding: 0 1rem;
        font-weight: 600;
      }
    }

    .app-qnnre__category {
      display: block;
      height: auto;
      max-height: none;
      min-height: 0;
      justify-content: flex-start;
      padding: 0;
      pointer-events: none;

      &:before {
        height: 90px;
        pointer-events: none;
      }
    }

    .app-qnnre__categoryContainer {
      padding: 0;
      padding-left: 1rem;
      padding-right: 1rem;
      pointer-events: initial;
      -webkit-overflow-scrolling: touch;
    }

    .gfx-podium {
      transform: translateX(-50%) scale(0.6);
      bottom: 90px;
    }

    .gfx-buildings {
      width: 100%;
      top: -90%;
    }

    .gfx-othertrees {
      width: 100%;
      top: -60%;
    }

    .app-qnnre__gfx {
      height: 20vh;
      max-height: initial;
      pointer-events: none;

      * {
        pointer-events: none !important;
      }

      &.app-qnnre__bg {
        height: 100%;
      }

      .app-qnnre-counter {
        bottom: 58px;
        transform: translateX(-50%) scale(0.8);
        transform-origin: 50% 100%;
        z-index: 3;
        padding: 0.5rem 2rem;
        width: 70%;
        max-width: 330px;
        height: auto;

        span {
          font-size: 1rem;
        }

        .app-qnnre__title {
          font-size: 0.8rem !important;
        }
      }
    }

    .app-form {
      .field-cta {
        margin-bottom: 5rem;
      }
    }
  }

  .page-results {
    .app-page__container {
      position: relative;
      height: initial;
      overflow-y: scroll;
    }

    .app-page__blade {
      padding: 2rem 1rem;
      min-height: unset;
    }

    .results-tips {
      margin-top: 5rem;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: radial-gradient(
          ellipse at center,
          rgba(0, 0, 0, 0.07) 0,
          rgba(0, 0, 0, 0) 75%
        );
      }

      .app-article {
        min-height: min-content;
      }

      .app-article__hero {
        min-height: 100px;
      }

      .hero-tree {
        width: 100%;

        .gfx-treehero {
          width: auto;
          height: 200%;
        }

        .gfx-co2logo {
          width: auto;
          height: 100%;
        }

        .gfx-featpodium {
          position: relative;
          bottom: auto;
          left: auto;
          width: 100%;
        }
      }
    }

    .results-share {
      .social-media {
        flex-direction: column;

        .app-article {
          max-width: 100%;
          margin: 1rem 0;
          padding-bottom: 1rem;

          .app-article__content {
            padding: 0 1rem;
          }
        }
      }
    }
  }

  .page-maintenance {
    .title {
      font-size: 1.1rem;
    }
  }

  .app-herolanding {
    padding: 4rem 1rem 1rem;
  }

  .hero-tree {
    bottom: -10px;
    top: auto;
    z-index: 2;
    transform: translateX(-50%) translateY(-40%) scale(0.45);
  }

  .cui-progress {
    display: none;
    visibility: hidden;
  }

  .cui-progress-v2 {
    &.is-crosswise {
      .cui-progress-v2__items {
        .cui-progress-v2__item {
          padding-top: 0;
          flex-basis: 3rem !important;
          span {
            display: none;
          }
          &::before {
            position: absolute;
            width: 1rem;
            top: 50%;
            right: 82.5%;
          }
        }
      }
    }
  }

  .app-tnc {
    ol {
      padding-left: 0.5rem;
      margin-bottom: 0.5rem;

      li {
        padding-left: 0.5rem;
        padding-bottom: 0.5rem;
      }
    }
  }

  .app-form {
    form {
      padding: 1rem 0;

      .label {
        width: 100%;
        flex-basis: 100%;
        text-align: left;
        justify-content: flex-start;
        min-height: min-content;
        margin-bottom: 0.5rem;
      }

      .control {
        width: 100%;
        flex-basis: 100%;
        max-width: 100%;
      }
    }

    .lm--formItem {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      & + .lm--formItem {
        margin-top: 0.5rem;

        .label {
          margin-top: 0.5rem;
        }
      }
    }

    .field-group {
      margin-bottom: 1rem !important;
    }

    .field-choose {
      .choices {
        .choose {
          &:nth-child(2n + 1) {
            margin-right: 0.25rem;
            min-width: calc(50% - 0.25rem);
            max-width: calc(50% - 0.25rem);
          }

          &:nth-child(2n + 2) {
            margin-left: 0.25rem;
            min-width: calc(50% - 0.25rem);
            max-width: calc(50% - 0.25rem);
          }

          margin: 0;
          min-width: 50%;
          max-width: 50%;
          margin-bottom: 0.5rem;
        }
      }
    }

    .field-cta {
      padding: 0.5rem 0;
      text-align: right;
      align-items: flex-end;
      justify-content: flex-end;
      flex-direction: column;
      margin-top: 0.5rem;

      .cta-button {
        width: 100%;
        margin: 0;
        order: 1;

        & + .cta-button {
          margin-bottom: 1.5rem;
        }

        &:first-child {
          order: 2;
        }
      }
    }
  }

  .gfx-buildings {
    top: 0;
  }

  .__landing {
    scroll-snap-type: initial;
    scroll-snap-points-y: initial;
    scroll-snap-type: y initial;
    overflow-x: initial;
    overflow-y: initial;
  }

  .__questionnaire {
    position: fixed;
    top: 0;
    left: 0;
    min-height: initial;
    max-height: 100vh; // Fallback
    max-height: calc(var(--vhValue, 1vh) * 100);

    .app-footer {
      bottom: 0rem;
      background-color: var(--color-bg);
    }
  }

  .__results {
    scroll-snap-type: initial;
    scroll-snap-points-y: initial;
    scroll-snap-type: y initial;
    overflow-x: initial;
    overflow-y: initial;
    overflow-y: scroll !important;

    .printable-results {
      .results-breakdown {
        .app-chart {
          .app-chart_breakdown {
            .app-chart_detail {
              .app-chart_detail__label {
                color: var(--chartDetailColor);

                &:before {
                  display: block;
                  border-width: 20px;
                  border-color: var(--chartDetailColor);
                }
              }
            }
          }
        }
      }
    }
  }
}

// MOBILE: Portrait
@media only screen and (min-width: 320px) and (max-width: 736px) and (orientation: portrait) {
  .app-herolanding {
    padding: 4rem 1rem;

    &.animate {
      .hero-tree {
        opacity: 0;
        animation: heroAppearM 1s ease-out 1.5s forwards;
      }
    }

    .hero-content {
      padding: 0;
      margin-top: 1rem;

      h2 {
        font-size: 1.3rem;
      }

      p,
      .landing-app__download {
        font-size: 0.875rem;
      }
    }

    .hero-gfx__bg {
      transform: scale(0.5);
      transform-origin: 50% 100%;
      height: calc(100% - 150px);

      .gfx-airplane {
        transform: translate(
          calc(var(--airplaneX) * 1px - 200px),
          calc((var(--airplaneY) + 100) * 1px)
        );
        animation-name: flyM;
      }
    }

    .hero-gfx__fg {
      max-height: 150px;

      .good-side {
        z-index: 1;

        .gfx-grass1 {
          top: 40%;
          right: 0;
          transform: translateY(-50%) scale(0.45);
        }

        .gfx-biker {
          top: -50%;
          transform: translateX(-20vw) scale(0.45);
          animation-name: bikerForM;
        }
      }

      .bad-side {
        z-index: 1;

        .gfx-wastesite {
          left: auto;
          right: 0;
          transform: translateX(30%) scale(0.45);
        }

        .gfx-drumcans {
          left: auto;
          right: 0;
          transform: translateX(30%) scale(0.45);
        }

        .gfx-stumpbranches {
          left: 0;
          transform: translateY(-50%) scale(0.45);
        }
      }
    }
  }

  .hero-tree {
    bottom: -10px;
    top: auto;
    z-index: 2;
    transform: translateX(-50%) translateY(-40%) scale(0.45);
  }

  .page-questionnaire {
    .gfx-trees {
      .gfx-trees__section {
        &#trees__section1 {
          transform: translateY(-40%);
        }
        &#trees__section2 {
          transform: translateY(-40%);
        }
      }
    }
  }

  .page-landing {
    #co2-landing-heroblade {
      height: 100vh; // Fallback
      height: calc(var(--vhValue, 1vh) * 100);
      max-height: 100vh; // Fallback
      max-height: calc(var(--vhValue, 1vh) * 100);
    }

    .hero-content {
      .hero-btn {
        margin-bottom: 0.5rem;
      }
    }
  }

  .page-results {
    .co2-cloud_amount {
      padding: 1rem;
      transform: scale(0.8);

      .co2-gfx_cloud_1 {
        width: 100%;
        top: 0;
        left: 0;
      }

      .co2-gfx_cloud_content {
        font-size: 2.8rem;
      }
    }

    .results-main {
      padding-top: 4rem;
      min-height: 100vh;
      height: 100vh; // fallback
      height: calc(var(--vhValue, 1vh) * 100);

      .total {
        flex-direction: column;
        font-size: 2rem;
        top: 40%;
      }

      .co2-ground {
        width: 100%;
        bottom: auto;
        top: 50%;
        transform: translateX(-50%) translateY(0%);
        padding: 1rem;
        font-size: 1.2rem;

        &.appear {
          opacity: 0;
          animation: totalDescriptionAppearM 1s ease 3s forwards;
        }
      }

      .app-qnnre__gfx {
        .gfx-buildings {
          width: 100%;
          height: auto;
          bottom: 50%;
        }

        .gfx-othertrees {
          width: 100%;
          height: auto;
          bottom: 50%;
        }

        .gfx-trees {
          transform: translateY(0);

          &:before {
            height: calc(100% - 80px);
          }

          .gfx-trees__section {
            transform: translateY(-30%);
          }
        }
      }
    }
  }
}

// MOBILE: Landscape
@media only screen and (min-width: 320px) and (max-width: 830px) and (max-height: 420px) and (orientation: landscape) {
  .app-herolanding {
    padding: 3rem 1rem 1rem;

    .hero-gfx {
      transform: scale(0.5);
    }
  }

  .page-landing {
    #co2-landing-heroblade {
      .hero-content {
        h1 {
          font-size: 1.5rem;
          margin-bottom: 0.5rem !important;
        }

        p {
          font-size: 1rem;
        }
      }
    }
  }

  .page-results {
    .results-main {
      .total {
        transform: translate(-50%, -50%) scale(0.8);
      }

      .co2-ground {
        width: 100% !important;
        height: auto;
        font-size: 1.2rem;
      }
    }

    .app-page__blade {
      &.results-share {
        padding-bottom: 2rem;
      }
    }
  }

  .app-modal {
    &.app-confirmation_dialog {
      display: block;
      max-height: 65%;
      top: 40% !important;
    }
  }
}
