@import '~@lumi/assets/icons/style.css';
@import '~@lumi/assets/fonts/geomanist-webfont/style.css';
@import '~@lumi/packs/dist/lumi.base.css';

@import '~@lumi/button/dist/index.css';
@import '~@lumi/checkbox/dist/index.css';

@import './styles/variables';
@import './styles/mixins';
@import './styles/animations';

*,
*:before,
*:after {
  box-sizing: border-box;
}

html {
  overflow: initial;
}

body {
  background-color: #ffffff;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  min-height: 100vh;
  &.with-spd-curtain {
    &:before {
      content: '';
      display: block;
      width: 100%;
      height: 147px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      z-index: -1;
      background: #fff;
      background: linear-gradient(180deg, #aeeaeb 0, #fff);
    }
  }

  #root {
    position: relative;
  }

  .App {
    position: relative;
  }
}

@import './styles/common';
@import './styles/page';
@import './styles/components/welcome';
@import './styles/components/cards';
@import './styles/components/form';
@import './styles/components/modal';
@import './styles/components/dialog';
@import './styles/components/datepicker';
@import './styles/components/floormap';
@import './styles/components/cardnode';
@import './styles/components/contentnode';
@import './styles/components/planitem';
@import './styles/components/togglebtns';
@import './styles/components/detail';
@import './styles/components/terms';
@import './styles/components/docviewer';
@import './styles/components/responsecard';
@import './styles/components/survey';
@import './styles/components/infocard';
@import './styles/components/bgimage';
@import './styles/components/hero';
@import './styles/components/tnc';

@import './styles/components/sky';
@import './styles/components/environment';
@import './styles/components/trees';
@import './styles/components/podium';
@import './styles/components/globecc';
@import './styles/components/smoke';

@import './styles/pages/landing';
@import './styles/pages/questionnaire';
@import './styles/pages/results';
@import './styles/pages/maintainance';

@import './styles/responsive';
@import './styles/mobile';
@import './styles/tablet';

@import './styles/overrides';
@import './styles/printable';
@import './styles/ie';
