.app-chart {
  &.appear {
    .graph-bar {
      .bar-wrap {
        animation-play-state: running;
      }
      .bar-breakpoint {
        .graph-breakpoint {
          animation-play-state: running;
        }
      }
    }
    .graph-bar__post {
      animation-play-state: running;
    }
  }
}

.app-chart_graph {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 250px;
  flex-direction: column;
  margin-bottom: 2rem;
}

.graph-bar {
  display: flex;
  justify-content: flex-end;

  &__container {
    display: flex;
    width: 80%;
    height: 64px;
    background-color: #F3F6F7;
    border-radius: 5px;
    padding: 0.5rem;
    position: relative;
  }

  &__pre {
    position: absolute;
    top: 50%;
    right: calc(100% + 1rem);
    transform: translateY(-50%);
    z-index: 10;
    white-space: nowrap;
    font-weight: 500;
  }
  
  &__post {
    position: absolute;
    top: 50%;
    left: calc(var(--chartDataPercentage) * 1%);
    // left: 0;
    opacity: 0;
    transform: translateY(-50%);
    z-index: 10;
    white-space: nowrap;
    font-weight: 500;
    padding: 0.5rem;
    animation: chartPostMove 2s ease-out 2.8s forwards;
    animation-play-state: paused;

    &.to-bottom {
      top: calc(100% + 0.5rem);
      left: auto;
      right: 0;
      margin-top: 0.5rem;
    }
  }

  .bar-wrap {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    position: relative;
    z-index: 1;
    transform: scaleX(0);
    transform-origin: 0% 50%;
    border-radius: 5px;
    animation: barScale 2s ease-out 1s forwards;
    animation-play-state: paused;
    overflow: hidden;

  }

  .bar-breakpoint {
    display: block;
    position: absolute;
    width: calc(100% - 1rem);
    height: calc(100% - 1rem);
    top: 0;
    left: 0;
    margin: 0.5rem;
    z-index: 5;
    counter-reset: breakpoint-index;
    .graph-breakpoint {
      counter-increment: breakpoint-index;
      animation: graphBreakpointMove 0.5s ease-out 3s forwards;
      animation-play-state: paused;
      opacity: 0;

      &:nth-child(0) {
        animation: graphBreakpointMove 0.5s ease-out 3.2s forwards;
        animation-play-state: paused;
        .breakpoint-stem {
          height: calc(1 * 50px);
        }
      }
      &:nth-child(1) {
        animation: graphBreakpointMove 0.5s ease-out 3.4s forwards;
        animation-play-state: paused;
        .breakpoint-stem {
          height: calc(1.5 * 100px);
        }
      }
      &:nth-child(2) {
        animation: graphBreakpointMove 0.5s ease-out 3.6s forwards;
        animation-play-state: paused;
        .breakpoint-stem {
          height: calc(2 * 100px);
        }
      }
      &:nth-child(3) {
        animation: graphBreakpointMove 0.5s ease-out 3.8s forwards;
        animation-play-state: paused;
        .breakpoint-stem {
          height: calc(2.5 * 100px);
        }
      }
      &:nth-child(4) {
        animation: graphBreakpointMove 0.5s ease-out 4s forwards;
        animation-play-state: paused;
        .breakpoint-stem {
          height: calc(3 * 100px);
        }
      }
      &:nth-child(5) {
        animation: graphBreakpointMove 0.5s ease-out 4.2s forwards;
        animation-play-state: paused;
        .breakpoint-stem {
          height: calc(3.5 * 100px);
        }
      }
      &:nth-child(6) {
        animation: graphBreakpointMove 0.5s ease-out 4.4s forwards;
        animation-play-state: paused;
        .breakpoint-stem {
          height: calc(4 * 100px);
        }
      }
      &:nth-child(7) {
        animation: graphBreakpointMove 0.5s ease-out 4.6s forwards;
        animation-play-state: paused;
        .breakpoint-stem {
          height: calc(4.5 * 100px);
        }
      }
    }
  }
}

.graph-breakpoint {
  position: absolute;
  bottom: -10px;
  left: calc(var(--chartBreakpointValue) * 1%);

  &.origin-left {
    .breakpoint-node {
      left: 0;
      right: auto;
      border-left: 2px solid #818A91;
      text-align: left;
    }
  }
  &.origin-right {
    .breakpoint-node {
      left: auto;
      right: 0;
      border-right: 2px solid #818A91;
      text-align: right;
    }
  }
 
  .breakpoint-stem {
    display: inline-block;
    width: 2px;
    height: 50px;
    // background-color: #818A91;
    border-left: 2px dashed #818A91;
  }
  .breakpoint-node {
    display: inline-block;
    position: absolute;
    top: 0;
    
    min-width: 100px;
    font-size: 0.75rem;
    padding: 0.25em 1em;
    
    white-space: nowrap;
    
    h4 {
      font-size: 1rem;
    }

  }
}

.graph-data {
  display: inline-block;
  // min-width: 100%;
  position: relative;
  background-color: var(--chartDataColor);
  transform-origin: left center;
  // transform: scaleX(var(--chartDataValue));
  width: calc(var(--chartDataValue) * 1%);
}

.app-chart_detail {
  display: flex;
  flex-direction: column;
  padding: 1rem;

  &__label {
    font-size: 1.25rem;
    color: #818A91;
    position: relative;
    &:before {
      content: "";
      display: inline-block;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      background-color: var(--chartDetailColor);
      margin-right: 0.5rem;
    }
  }
  &__value {
    font-size: 2rem;
    color: #4F5A60;
  }
  &__description {
    font-size: 1.25rem;
    color: #4F5A60;
  }
}

.app-chart_breakdown {
  display: flex;
  flex-wrap: wrap;
  .app-chart_detail {
    width: calc( (1 / 3 * 100%) - 2rem);
    max-width: calc( 1 / 3 * 100%);
    margin: 1rem;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .app-chart_breakdown {
    .app-chart_detail {
      width: calc( (1 / 2 * 100%) - 1rem);
      margin: 0.5rem;
      max-width: initial;
      padding: 0.5rem;
    }
  }
  .app-chart_detail__label {
    padding-left: 1.5rem;
    font-size: 1rem;
    &:before {
      position: absolute;
      top: 0.5rem;
      left: 0;
    }
  }
  .app-chart_detail__value {
    padding-left: 1.5rem;
    font-size: 1.5rem;
  }
  .app-chart_detail__description {
    padding-left: 1.5rem;
    font-size: 0.75rem;
  }
  .graph-bar__container {
    width: 100%;
  }
  .graph-bar__pre {
    right: auto;
    left: 0;
    top: calc(100% + 0.5rem);
    margin-top: 0.5rem;
  }
}

@keyframes barScale {
  0% {
    transform: scaleX(0) translateZ(0);
  }
  100% {
    transform: scaleX(1) translateZ(0);
  }
}

@keyframes chartPostMove {
  0% {
    // left: 0;
    opacity: 0;
  }
  100% {
    // left: calc(var(--chartDataPercentage) * 1%);
    opacity: 1;
  }
}

@keyframes graphBreakpointMove {
  0% {
    transform: translateY(10%) translateZ(0);
    opacity: 0;
  }
  100% {
    transform: translateY(0%) translateZ(0);
    opacity: 1;
  }
}
