.__landing {
  scroll-snap-type: mandatory;
  scroll-snap-points-y: repeat(100vh);
  scroll-snap-type: y mandatory;
  overflow-x: hidden;
  overflow-y: scroll;
}

.page-landing {
  padding-bottom: 0 !important;

  .section-blade {
    .blade-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
    }
  }
  .featured-list {
    display: flex;
    flex-direction: row;
    padding: 1rem 0;
    &.animate {
      .app-article {
        .app-article__hero {
          opacity: 0;
        }
        .app-article__header {
          opacity: 0;
        }
        .app-article__content {
          opacity: 0;
        }
      }

      &.animate-stagger {
        &.appear {
          .app-article {
            $appearFeatLitItemDelay: 0;
            @for $i from 1 through 10 {
              &:nth-child(#{$i}) {
                .app-article__hero {
                  $appearFeatLitItemDelay : $appearFeatLitItemDelay + 0.1;
                  animation: appear 1s ease-out #{$appearFeatLitItemDelay}s forwards;
                }
                .app-article__header {
                  $appearFeatLitItemDelay : $appearFeatLitItemDelay + 0.2;
                  animation: appear 1s ease-out #{$appearFeatLitItemDelay}s forwards;
                }
                .app-article__content {
                  $appearFeatLitItemDelay : $appearFeatLitItemDelay + 0.2;
                  animation: appear 1s ease-out #{$appearFeatLitItemDelay}s forwards;
                }
              }
              $appearFeatLitItemDelay : $appearFeatLitItemDelay + 0.3;
            }
          }
        }
      }
    }
  }

  .app-page__container {
    position: relative;
    width: 100%;
  }

  #co2-landing-heroblade {
    height: 100vh;
    overflow: hidden;
    scroll-snap-align: start;
  }

  #co2-landing-blade2 {
    scroll-snap-align: start;
    height: 100vh;
    background-color: #ffffff;
    .blade-container {
      justify-content: center;
      align-items: center;
      padding: 2rem; 
    }
    .app-article {
      align-items: center;
      .app-article__hero {
        .gfx-globecc {
          width: 100%;
        }
      }
    }
  }
  #co2-landing-blade3 {
    height: 100vh;
    justify-content: center;
    align-items: center;
    background-color: #F3F6F7;
    scroll-snap-align: start;
    h1 {
      max-width: 777px;
    }
    .blade-container {
      height: auto;
    }
  }
}
