.app-cardselection {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;

  &.loaded {
    .app-card {
      transition: all .2s ease;
    }
  }

  &__item {
    h2 {
      margin: 2.5rem 0 0.5rem;
      font-size: 1.75rem;
      font-weight: 600;
      line-height: 1.3;
    }

    h3 {
      margin: 0.25rem 0 0;
      font-size: 1.70rem;
      font-weight: 400;
      line-height: 1.2;

      span {
        display: block;
        font-size: 0.9em;
        margin-top: 0.1em;
      }
    }

    figure {
      line-height: 0;
      width: 144px;
      height: 144px;
      border: 4px solid #fff;
      border-radius: 50%;
      overflow: hidden;
      display: inline-flex;
      justify-content: center;
      align-items: center;

      img, svg {
        width: 100%;
        height: 100%;
        padding: 1.65rem;
      }
    }
  }
}

.app-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: calc(100% * 1 / 3);
  height: 100px;
  padding: 30px;
  transform: scale(1);
  transition: none;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;

  &:active {
    transform: scale(1.05) !important;
  }

  &.is-active {
    transform: scale(1.05) !important;
  }
}
