// TABLET

@media only screen and (min-width: 737px) and (max-width: 1100px) {
  .app-page {
    &.page-landing {
      min-height: initial;
    }
    &.page-questionnaire {
      min-height: initial;
      height: 100vh; // Fallback
      height: calc(var(--vhValue, 1vh) * 100);
      max-height: 100%; // Fallback
      max-height: calc(var(--vhValue, 1vh) * 100);
      .app-page__container {
        min-height: initial;
        padding-left: 2rem;
        padding-right: 2rem;
      }
    }
  }

  .page-landing {
    .app-page__container {
      position: relative;
      height: initial;
      overflow: initial;
    }
    .section-blade {
      min-height: initial;
      min-height: min-content;
    }

    #co2-landing-heroblade {
      height: 100vh; // Fallback
      height: calc(var(--vhValue, 1vh) * 100);
      max-height: 100vh; // Fallback
      max-height: calc(var(--vhValue, 1vh) * 100);
      .app-herolanding {
        .hero-gfx__fg {
          .good-side {
            .gfx-grass1 {
              right: 350px;
            }
          }
          .bad-side {
            .gfx-wastesite {
              left: 350px;
            }
            .gfx-stumpbranches {
              left: 280px;
            }
          }
        }
      }
      .hero-content {
        max-width: 700px;
        h1 {
          margin-bottom: 1.5rem;
        }
        .hero-btn {
          margin-top: 1.5rem;
        }
      }
    }

    #co2-landing-blade2 {
      height: auto;
      padding: 2rem 0;
    }

    #co2-landing-blade3 {
      height: auto;
      padding: 5rem 0;
    }
  }

  .page-questionnaire {
    .app-qnnre__gfx {
      .app-qnnre-counter {
        transform: translateX(-35%);
      }
    }
  }

  .page-results {
    .app-page__container {
      position: relative;
      height: initial;
      overflow-y: scroll;
    }
    .results-main {
      .co2-ground {
        width: 80%;
      }
    }
    .results-breakdown {
      min-height: initial;
      min-height: min-content;
    }
    .results-share {
      .social-media {
        .app-article {
          max-width: calc(50% - 1rem);
          margin: 1rem 0.5rem;
          flex-basis: 50%;
          .app-article__header {
            padding-top: 2rem;
            padding-bottom: 0;
            h1 {
              margin: 0;
            }
          }
          .app-article__content {
            padding: 1rem 2rem;
          }
        }
      }
    }
  }

  .page-maintenance {
    .title {
      font-size: 1.3rem;
    }
  }

  .gfx-buildings {
    width: 100%;
    top: -5%;
  }

  .gfx-trees {
    .gfx-trees__section {
      transform: translateY(10px);
    }
  }

  .__landing {
    scroll-snap-type: initial;
    scroll-snap-points-y: initial;
    scroll-snap-type: y initial;
    overflow-x: initial;
    overflow-y: initial;
  }

  .__questionnaire {
    position: fixed;
    top: 0;
    left: 0;
    min-height: initial;
    max-height: 100vh; // Fallback
    max-height: calc(var(--vhValue, 1vh) * 100);
    .app-footer {
      bottom: 0rem;
    }
  }
  .__results {
    scroll-snap-type: initial;
    scroll-snap-points-y: initial;
    scroll-snap-type: y initial;
    overflow-x: initial;
    overflow-y: scroll !important;
    // .printable-results {
    //   display: none;
    // }
  }
}

@media only screen and (min-width: 737px) and (max-width: 1100px) and (orientation: portrait) {
  .app-page {
    &.page-questionnaire {
      .app-page__container {
        padding-bottom: 20vh;
      }
    }
  }
  .page-results {
    .results-tips {
      .hero-tree {
        width: 100%;
        .gfx-featpodium {
          width: 100%;
        }
      }
    }
  }
  .gfx-buildings {
    width: 100%;
  }
}

// Larger Tablets eg. iPad Pro 12in
@media only screen and (min-width: 1024px) and (max-width: 1366px) and (max-height: 1024px) and (orientation: portrait) {
  .app-page {
    &.page-questionnaire {
      .app-page__container {
        padding-bottom: 20vh;
      }
    }
  }
  .page-landing {
    #co2-landing-heroblade {
      .app-herolanding {
        &.animate {
          .hero-tree {
            animation: heroAppearT 1s ease-out 1.5s forwards;
          }
        }
        .hero-gfx__fg {
          .good-side {
            .gfx-grass1 {
              right: 350px;
            }
          }
          .bad-side {
            .gfx-wastesite {
              left: 350px;
            }
            .gfx-stumpbranches {
              left: 280px;
            }
          }
        }
      }
    }
  }
  .__landing {
    scroll-snap-type: initial;
    scroll-snap-points-y: initial;
    scroll-snap-type: y initial;
    overflow-x: initial;
    overflow-y: initial;
  }
  .__results {
    scroll-snap-type: initial;
    scroll-snap-points-y: initial;
    scroll-snap-type: y initial;
    overflow-x: initial;
    overflow-y: initial;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1366px) and (max-height: 1024px) and (orientation: landscape) {
  .app-page {
    &.page-landing {
      .app-page__container {
        position: relative;
        height: initial;
        overflow: initial;
      }
      .section-blade {
        min-height: initial;
        min-height: min-content;
      }
    }

    &.page-questionnaire {
      min-height: initial;
      height: 100vh; // Fallback
      height: calc(var(--vhValue, 1vh) * 100);
      max-height: 100%; // Fallback
      max-height: calc(var(--vhValue, 1vh) * 100);
      .app-page__container {
        min-height: initial;
      }
    }

    &.page-results {
      .app-page__container {
        position: relative;
        height: initial;
        overflow-y: scroll;
      }
    }
  }

  .gfx-trees {
    .gfx-trees__section {
      &#trees__section1 {
        transform: translateX(-60px);
      }
      &#trees__section2 {
        transform: translateX(60px);
      }
    }
  }

  .page-landing {
    #co2-landing-heroblade {
      height: 100vh; // Fallback
      height: calc(var(--vhValue, 1vh) * 100);
      max-height: 100vh; // Fallback
      max-height: calc(var(--vhValue, 1vh) * 100);
    }
  }

  .__questionnaire {
    position: fixed;
    top: 0;
    left: 0;
    min-height: initial;
    max-height: 100vh; // Fallback
    max-height: calc(var(--vhValue, 1vh) * 100);
    .app-footer {
      bottom: 0rem;
    }
  }

  .__landing {
    scroll-snap-type: initial;
    scroll-snap-points-y: initial;
    scroll-snap-type: y initial;
    overflow-x: initial;
    overflow-y: initial;
  }
  .__results {
    scroll-snap-type: initial;
    scroll-snap-points-y: initial;
    scroll-snap-type: y initial;
    overflow-x: initial;
    overflow-y: initial;
    overflow-y: scroll !important;
    .printable-results {
      display: block;
    }
  }
}

@media only screen and (min-width: 737px) and (max-width: 1024px) and (max-height: 1024px) and (orientation: landscape) {
  .gfx-trees {
    .gfx-trees__section {
      &#trees__section1 {
        transform: translateX(-30px);
      }
      &#trees__section2 {
        transform: translateX(30px);
      }
    }
  }
}
