.cui-progress-v2 {
  display: flex;
  z-index: 10;

  &.is-crosswise {
    .cui-progress-v2__items {
      flex-direction: row;
      justify-content: center;
      padding: 0.85rem 0;
      .cui-progress-v2__item {
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        padding: 0 0.5rem;
        padding-top: 1.7rem;
        position: relative;
        text-align: center;
        flex-basis: 12.5rem;
        width: 12.5rem;

        &::before {
          content: '';
          position: absolute;
          width: 1rem;
          height: 2px;
          background-color: var(--bgColor);
          top: 60%;
          right: 90%;
          transition: .4s ease;
        }
        &:first-child{
          &::before {
            display: none;
          }
        }
        
        &--icon {
          width: 24px;
          height: 24px;
          background-color: var(--bgColor);
          transition: all .4s ease;
        }
        &--cate-name {
          color: var(--bgColor);
          transition: all .4s ease;
        }
      }
    }
  }

  &__items {
    display: flex;
    flex-direction: column;
    font-size: 0.875rem;
    padding: 1rem 0;
    width: 100%;
  }
  &__item {
    position: relative;
    padding-top: 3rem;
    padding-left: 2rem;
  }
}
