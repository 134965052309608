.app-article {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  width: 100%;

  &.animate {
    .app-article__header {
      opacity: 0;
    }
    .app-article__hero {
      opacity: 0;
    }
    p {
      opacity: 0;
    }
    &.appear {
      .app-article__header {
        animation: articleAppear 0.8s ease-out 0s forwards;
      }
      p {
        animation: articleAppear 0.8s ease-out .5s forwards;
      }
      .app-article__hero {
        animation: articleAppear 0.8s ease-out .7s forwards;
      }
      .app-article__content {
        animation: articleAppear 0.8s ease-out 0s forwards;
      }
      .app-article__description {
        animation: articleAppear 0.8s ease-out 0s forwards;
      }
    }
  }
  
  &.align-left {
    text-align: left;
  }

  &.align-center {
    text-align: center;
  }
  
  &.align-right {
    text-align: right;
  }

  &.is-crosswise {
    flex-direction: row;
    .app-article {
      &__hero {
        flex-basis: 50%;
        flex-grow: 1;
      }

      &__header {
        * {
          text-align: left;
        }
      }

      &__content {
        flex-basis: 50%;
        flex-grow: 1;
      }
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 0 1rem;
    margin-bottom: 1rem;
    p {
      margin-bottom: 1rem;
    }
  }

  &__hero {
    display: flex;
    flex-direction: column;
    min-height: 100px;
    align-items: center;
  }
}

@media only screen and (min-width: 320px) and (max-width: 767px) {
  .app-article {
    &.is-crosswise {
      flex-direction: column;
    }
    .app-article__content {
      padding: 0;
    }
  }
}

@keyframes articleAppear {
  0% {
    opacity: 0;
    transform: translateY(10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0%);
  }
}
