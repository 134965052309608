.app-bg--main {
  display: block;
  width: 100%;
  height: 100%;
  background-image: url("/images/bg.jpg");
  background-size: cover;
  background-position: center center;

  &:before {
    display: none;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    opacity: 0.8;

    @include bg-gradient;
  }

  &:after {
    display: none;
    width: 100%;
    height: 100%;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    opacity: 0.35;
    background-color: rgba(0, 27, 48, 1);
  }
}

.app-header {
  display: flex;
  width: 100%;
  height: 70px;
  padding: 0 3%;
  justify-content: flex-start;
  align-items: flex-end;
  background: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;

  .logo {
    font-size: 0;
    line-height: 0;
    width: 164px;
    height: 94px;
    padding: 0;

    img,
    svg {
      width: 100%;
      height: 100%;
    }
  }

  &.type-profile,
  &.type-frame {
    width: 18.59vw;
    padding: 0;
    height: 130px;
    padding: 0 1.5vw;

    .logo {
      width: 100%;
    }
  }

  &.type-generic,
  &.type-landing {
    height: 70px;
    padding: 1rem 1.5625rem;
    align-items: flex-start;

    .logo {
      height: auto;
      width: 213px;

      img,
      svg {
        width: 100%;
        height: auto;
      }
    }
  }
}

.logo {
  font-size: 0;
  line-height: 0;
  width: 100%;
  height: auto;
  padding: 2.5rem;
  margin: 0;

  img,
  svg {
    width: 100%;
    height: 100%;
  }
}

.app-button {
  min-width: 175px;
  text-align: center;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 600;

  &:focus {
    outline: none;
  }

  * {
    pointer-events: none;
  }
}

.app-cta {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding: 1rem 0;

  .app-button {
    margin-right: 1rem;

    &:last-child {
      margin-right: 0;
    }
  }
}

.app-termsbox {
  padding: 1rem 1.4rem;
  padding-right: 0;
  width: 100%;
  height: 100%;
  border: 1px solid rgba(206, 213, 220, 1);
  margin: 1.5rem 0 1rem;

  &__content {
    overflow: auto;
    width: 100%;
    height: 100%;
    padding-right: 1.5rem;

    & > div {
      margin-bottom: 0.7em;
    }
  }

  h2 {
    font-size: 1.2rem;
  }

  h3 {
    font-size: 1.1rem;
  }

  p {
    font-size: 1rem;
    margin-bottom: 0.7em;
    line-height: 1.3;
    color: rgba(129, 138, 145, 1);
  }
}

.app-contentnode {
  display: inline-block;

  // margin-bottom: 1.37rem;
  white-space: normal;
  word-break: break-word;

  &__label {
    display: block;
    font-size: 1.25rem;
    color: #ffffff;
    opacity: 0.7;
  }

  &__value {
    display: block;
    font-size: 1.5rem;
  }
}

.app-frame {
  display: block;
  width: 100%;
  height: 100%;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;

  iframe,
  frame {
    width: 100%;
    height: 100%;
  }
}

.cta-button {
  min-width: 175px;
  justify-content: center;
  text-align: center;

  &.lm--button--secondary {
    &.loading {
      svg {
        path {
          fill: #00b0b2;
        }
      }
    }

    &:disabled {
      &.loading {
        svg {
          path {
            fill: #e7ecef;
          }
        }
      }
    }
  }

  &.loading {
    padding: 0;
    position: relative;

    svg {
      height: 100% !important;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }

    span {
      opacity: 0;
      visibility: hidden;
    }
  }
}

.btn-endsession {
  color: #fff;
  position: relative;
  display: inline-flex;
  justify-content: flex-start;
  align-items: center;
  min-height: 69px;
  width: 100%;
  outline: none;
  padding: 0.4em;

  &:focus {
    outline: none;
    border: 0;
  }

  &:before {
    display: inline-block;
    position: relative;
    content: '';
    width: 15px;
    height: 15px;
    border: 2px solid #fff;
    border-top: none;
    border-right: none;
    transform-origin: center center;
    transform: rotate(45deg);
    margin-right: 0.5rem;
  }
}

.btn-logout {
  display: inline-block;
  position: absolute;
  top: 10px;
  left: 50%;
  z-index: 3000;
  transform: translateX(-50%);
  outline: none !important;
  padding: 10px 20px;
  opacity: 0;
}

.app-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;

  svg {
    max-width: 100px;
  }
}

.env-indicator {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 5000;

  &.dev {
    background-color: #2f3031;
  }

  &.qa {
    background-color: #ffc75e;
  }
}

.ctas {
  margin-top: 0.5rem;
  padding: 1rem;

  .cta-button {
    margin: 0.5rem;
    display: inline-flex;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }

    &.cta-back {
      justify-self: flex-start;
      margin-right: auto;
    }

    font-weight: 600;
    min-width: 175px;
    text-align: center;
    justify-content: center;
  }
}

.accountmenu {
  display: flex;
  position: absolute;
  top: 5%;
  right: 5%;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  z-index: 5000;

  i {
    display: inline-block;
    line-height: 0;
    margin-right: 0.3rem;
  }

  .account-name {
    font-size: 1.25rem;
    font-weight: 500;
    margin-right: 0.25rem;
  }

  button {
    padding: 0;
    font-size: 1.25rem;
  }
}

.yearly-servicing {
  padding: 0;
  font-size: 1.25rem;
  font-weight: 400;
  margin-top: 0.5rem;

  span {
    font-weight: 500;
  }
}

.contract-signed,
.view-pdf {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: flex-start;

  i {
    margin-right: 1rem;
    margin-top: 3px;
  }

  p {
    text-align: left;
    line-height: 1.6;

    span {
      color: rgba(241, 161, 102, 1);
      font-weight: 500;
    }
  }
}

.view-pdf {
  position: relative;
  align-items: center;

  p {
    font-size: 1.25rem;
    font-weight: 500;
  }

  .pdf-ctas {
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;

    .pdf-cta {
      margin-right: 1rem;
      display: inline-block;

      &:last-child {
        margin-right: 0;
      }
    }
  }
}

.print-frame {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  z-index: -1;
  top: -100%;
  left: 0;
}

.printable {
  padding: 1rem;
  position: relative;
  z-index: 10;

  .page-title {
    font-size: 1rem;
    text-align: center;
  }
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.col {
  flex-direction: column;

  &.align-end {
    align-items: flex-end;
  }

  &.align-start {
    align-items: flex-start;
  }

  &.align-center {
    align-items: center;
  }

  &.valign-end {
    justify-content: flex-end;
  }

  &.valign-start {
    justify-content: flex-start;
  }

  &.valign-center {
    justify-content: center;
  }
}

.row {
  flex-direction: row;

  &.align-end {
    justify-content: flex-end;
  }

  &.align-start {
    justify-content: flex-start;
  }

  &.align-center {
    justify-content: center;
  }

  &.valign-end {
    align-items: flex-end;
  }

  &.valign-start {
    align-items: flex-end;
  }

  &.valign-center {
    align-items: center;
  }
}

.overflow-hidden {
  overflow: hidden;
}

h1 {
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
}

h2 {
  font-size: 1.75rem;
  font-weight: 500;
  line-height: 1.5;
}

h3 {
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.5;
}

h4 {
  font-size: 1.25rem;
  font-weight: 500;
  line-height: 1.5;
}

p {
  font-size: 1rem;
  line-height: 1.6;
}

.highlight {
  color: #ff9d5a;
}

.app-footer {
  display: flex;
  position: absolute;
  bottom: 0;
  padding: 2rem;
  font-size: 0.875rem;
  color: #818a91;
  width: 100%;
  justify-content: center;
  align-items: center;
  z-index: 100;
  flex-direction: column;
}

.app-back2home {
  margin-bottom: 2rem;
}

.animate {
  .appear {
    animation: appear 0.8s ease-out forwards;
  }
}

.invisible {
  visibility: hidden !important;
  opacity: 0 !important;
  display: none !important;
}
