.gfx-podium {
  position: absolute;
  bottom: 25%;
  left: calc(50% - 215.11px/2 - 119.44px);
  line-height: 0;
  z-index: 2;
  max-height: 43px;
  // height: 15.11%; // 43 / 284.39 * 100%
  transition: all .5s ease;
  transform: scale(1); 

  .gfx-bush1, .gfx-bush2 {
    position: absolute;
    // height: 62.79%; // 27 / 43 * 100%
  }
  .gfx-bush1 {
    bottom: 67%;
    left: 10%;
    z-index: 3;
  }
  .gfx-bush2 {
    bottom: 75%;
    right: 10%;
    z-index: 1;
  }
  .gfx-podium_star {
    position: absolute;
    bottom: 70%;
    z-index: 2;
    left: 50%;
    transform: translateX(-50%);
    svg {
      transform-origin: center bottom;
    }
    .co2-category_home {
      transform: scale(0);
      // transition: all 0.4s ease-in-out;

      &.show {
        // animation: popCategory .8s cubic-bezier(0.310, 0.440, 0.445, 1.650) 0.5s forwards;
        animation: popCategory .8s ease-in 0.5s forwards;
      }
    }
    .co2-category_recycle {
      transform: scale(0);

      &.show {
        animation: popCategory .8s ease-in 0.5s forwards;
      }
    }
    .co2-category_shopping {
      transform: scale(0);

      &.show {
        animation: popCategory .8s ease-in 0.5s forwards;
      }
    }
    .co2-category_food {
      transform: scale(0);

      &.show {
        animation: popCategory .8s ease-in 0.5s forwards;
      }
    }
    .co2-category_landtransport {
      transform-origin: 55% 100%;
      transform: scale(0) translateY(5px);
      &.show {
        animation: popCategoryTransport .8s ease-in 0.5s forwards;
      }
    }
    .co2-category_travel{
      transform: scale(0);

      &.show {
        animation: popCategory .8s ease-in 0.5s forwards;
      }
    }
  }
  .gfx-podium-platform {
    height: 100%;
  }
}
