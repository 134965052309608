@import "../variables";

.app-form {
  display: flex;
  text-align: center;
  width: 100%;
  flex-direction: column;

  &.form-confirmation {
    max-width: 575px;
    margin: auto auto;

    & .cta-container {
      width: 575px;
      margin: 0 auto;
    }
  }

  &.form-confirmation.summary-page {
    max-width: 960px;
    font-size: 1rem;
    line-height: 1.75;
  }

  &.form-verification {
    max-width: 375px;

    p {
      margin: 1rem;
    }

    .field-otp__cont {
      width: 100%;
      justify-content: space-between;
      align-items: center;

      & > div {
        // flex-grow: 1;
        width: 45px;
        margin-right: 1rem;
      }
    }
  }

  &.form-col {
    form {
      .form-item {
        flex-direction: column;
      }

      .label {
        width: 100%;
        padding: 0.5rem;
        text-align: left;
      }

      .control {
        width: 100%;
      }
    }

    .form-cta {
      padding-left: 0;
      margin: 2rem 0;
      flex-direction: row !important;
    }
  }

  form {
    padding: 2rem 0;

    .subtitle {
      width: 30.5%;
      flex-basis: 30.5%;
      text-align: right;
      font-size: 1.5rem;
      font-weight: 500;
    }

    .label {
      display: flex;
      width: 45%; // 240px
      flex-basis: 45%;
      text-align: left;
      font-size: 0.875rem;
      min-height: 44px;
      align-self: flex-start;
      justify-content: flex-start;
      align-items: center;
      padding-right: 1.5rem;

      img {
        max-height: 72px;
      }
    }

    .control {
      width: 65.39%; // 376px
      flex-basis: 65.39%;
      max-width: 376px;
      text-align: left;
      position: relative;

      .control-wrap {
        display: flex;
        width: 100%;
        flex-direction: row;
      }

      input, select, textarea {
        font-size: 1rem;
        padding: 0.75rem;
      }

      input {
        appearance: none !important;

        &:disabled {
          -webkit-text-fill-color: #333333 !important;
        }
      }

      p {
        font-size: 0.875rem;
      }
    }

    .error-wrap {
      display: flex;
      width: 100%;
      flex-direction: row;
    }

    .field-input {
      &.is-modified {
        input {
          @include field-modified;
          &:focus {
            border-color: $color-mint;
          }
        }
      }
      &.is-error {
        input {
          @include field-error
        }
      }
    }

    .field-note {
      display: block;
      margin-top: 0.5rem;
      color: rgba(129, 138, 145, 1);
      font-size: 0.75rem;
    }

    .field-info {
      display: flex;
      margin-top: 0.5rem;
      color: rgba(129, 138, 145, 1);
      font-size: 0.8rem;

      i {
        width: 24px;
        height: 24px;
        margin-right: 0.5rem;
        opacity: 0.5;

        svg {
          width: 100%;
          height: 100%;
        }
      }

      span {
        display: inline-block;
        line-height: 1.3;
        width: calc(100% - (24px + 0.5rem));
        font-size: 0.875rem;
      }
    }

    .field-unit {
      display: inline-block;
      position: absolute;
      top: calc(0.75rem + 2px);
      right: 1rem;
      opacity: 0.7;
    }

    .control-container {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      & > span {
        margin: 0 0.5rem;
      }
    }
  }

  .form-cta {
    display: flex;
    justify-content: flex-end;
    padding-left: 40.0%;
    margin-top: 1.5rem;
    margin-bottom: 2rem;
    width: 100%;

    .cta-button {
      justify-content: center;
      font-size: 1rem;
      margin: 0 0.5em;
      padding: 0.5625em 1em;
      min-width: 160px;
      font-weight: 600;

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &.cta-proceed {
        min-width: 205px;
      }
    }
  }

  .field-otp {
    &__input {
      min-width: 45px !important;
      min-height: 44px;
      flex-basis: 40px;
      padding: 0.5em !important;
    }

    &__cont {
      & > div {
        margin-right: 0.5rem;

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .field-prefix {
    &__pref {
      width: 15%;
      flex-basis: 15%;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      border-radius: 0;
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
      border-color: #ced5dc;
    }

    &__value {
      width: 85%;
      flex-basis: 85%;
      border-radius: 0;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
      border-left: 0;
      border-color: #ced5dc;
    }
  }

  .field-mobile {
    .control {
      display: flex;
      flex-direction: column;

      .mobile_prefix {
        width: 46px;
        flex-basis: 46px;
        display: inline-block;
        text-align: center;
        border-radius: 5px 0 0 5px;
        border-right: 0;
        border-color: #ced5dc;
        padding: .6875rem .5rem;
        background-color: #ffffff;
      }

      .number {
        width: calc(100% - 46px);
        flex-basis: calc(100% - 46px);
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  .field-split {
    .control {
      display: flex;
      flex-direction: column;

      input {
        flex-grow: 1;

        &:first-of-type {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          text-indent: 16px;
          border-right: 0;

          &:focus {
            border-right: 1px solid $teal-colour;
          }
        }

        &:last-child {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }

    .prices {
      display: flex;
      flex-direction: column;

      input {
        flex-grow: 1;

        &:first-of-type {
          text-indent: 0px;
          border-right: 1px solid #ced5dc;
        }

        &:first-of-type, &:last-child {
          border-radius: .3125rem;
        }
      }

      span {
        display: flex;
        align-self: center;
        padding: 5px;
      }
    }

    &.is-modified-1 {
      input {
        &.first {
          @include field-modified;
          &:focus {
            border-color: $color-mint;
          }
        }
      }
    }
    
    &.is-modified-2 {
      input {
        &.second {
          @include field-modified;
          &:focus {
            border-color: $color-mint;
          }
        }
      }
    }

    &.is-error {
      input {
        &.first, &.second {
          @include field-error
        }
      }
    }
    
    .error-wrap {
      .error-block {
        flex-grow: 1;
        &:first-child {
          padding-right: 0.8rem;
        }
        &:last-child {
          padding-left: 0.8rem;
        }
      }
    }
  }

  .field-date {
    &.is-error {
      .app-button {
        border-color: #e54545;
      }
    }

    .datepicker-focusinput {
      position: absolute;
      top: 0;
      opacity: 0;
    }

    .app-button {
      display: flex;
      width: 100%;
      border: 1px solid #ced5dc;
      border-radius: .3125rem;
      position: relative;
      z-index: 1;

      // max-width: 220px;
      font-weight: 400;
      text-align: left;
      justify-content: flex-start;

      &:disabled {
        color: #4f5a60;
        border-color: #e7ecef;
        background-color: #f3f6f7;
        cursor: not-allowed;
      }

      &:focus {
        border-color: #00b0b2;
        outline: none;
      }

      svg {
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translateY(-50%);
      }
    }
  }

  .field-choose {
    .choices {
      display: flex;
      flex-wrap: wrap;

      .choose {
        flex-grow: 1;
        margin: 0.2rem;
        max-width: calc(33% - 0.4rem);
        min-width: calc(33% - 0.4rem);
        flex-basis: calc(33% - 0.4rem);
        position: relative;

        .choose-input {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          &:focus {
            outline: none;
            appearance: none;
          }

          &:checked { 
            & + .choose-label {
              &:before {
                margin-left: 0;
                transform: scale(1) rotate(45deg) translate3d(-50%,0,0);
              }
            }
          }
        }

        .choose-label {
          display: flex;
          width: 100%;
          padding: 0.5rem 1rem;
          text-align: center;
          justify-content: center;
          align-items: center;
          min-height: 44px;
          border: .0625rem solid var(--color-input-border);
          border-radius: .3125rem;
          transition: all .15s ease-in-out;
          color: var(--color-text);
          &:before {
            content: "";
            width: 8px;
            height: 13px;
            margin-right: .9375rem;
            margin-left: -1.4375rem;
            transform: scale(0) rotate(45deg) translate3d(-50%,0,0);
            border-color: #00b0b2;
            border-style: solid;
            border-width: 0 2px 2px 0;
            transition: all .1s linear;
            transform-origin: 50% 100%;
          }
          &.active {
            border-color: $teal-colour;
            &:before {
              margin-left: 0;
              transform: scale(1) rotate(45deg) translate3d(-50%,0,0);
            }
          }
        }

        .choose-input:checked + .choose-label, .choose-label:hover {
          border-color: $teal-colour;
        }

      }
    }
  }

  .field-item {
    &.field-item--content {
      align-items: center;
    }
  }

  .field-terms {
    padding-left: 3rem;
    text-align: right;

    a {
      display: inline-block;
    }
  }

  .lm--formItem {
    display: flex;
    & + .lm--formItem {
      margin-top: 1rem;
    }
  }

  .lm--formItem--inline {
    align-items: flex-start;
    justify-content: flex-end;
  }

  .lm--formItem--center {
    justify-content: center;
  }

  .lm--inputGroup {
    .lm--inputGroup-prefix {
      input {
        &[type=text] {
          padding: 0;
          background: none;
          font-size: 1rem;
          width: 15px;
          color: #333333;
          text-align: left;
          text-indent: 0;
          border: 0;
          outline: none !important;

          &:focus {
            border: 0 !important;
          }
        }
      }
    }
  }

  //Hide spin button for input number
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    appearance: none;
    margin: 0;
  }

  [type=color], 
  [type=date], 
  [type=datetime-local], 
  [type=datetime], 
  [type=email], 
  [type=month], 
  [type=number], 
  [type=password], 
  [type=search], 
  [type=tel], 
  [type=text], 
  [type=time], 
  [type=url], 
  [type=week], 
  select, 
  textarea {
    display: block;
    width: 100%;
    padding: .6875rem 1rem;
    font-size: 1rem;
    color: #4f5a60;
    line-height: 1.25;
    background: #fff;
    border: 1px solid #ced5dc;
    border-radius: .3125rem;
  }

  select { 
    display: block;
    padding: .6875rem 2.5rem .6875rem 1rem;
    appearance: none;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSI3Ij48cGF0aCBmaWxsPSJub25lIiBzdHJva2U9IiM5QkE2QUEiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIiBkPSJNMSAxLjEyMkw1LjkzOCA2IDExIDEiLz48L3N2Zz4=);
    background-position: right 1rem center;
    background-repeat: no-repeat;
    border-radius: .3125rem;
    cursor: pointer;
  }
}
