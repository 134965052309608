.gfx-factory__smoke {
  &.smoke2 {
    transform-origin: 50% 100%;
    transform: scale(0.5);
  }
  .smk {
    transform-box: fill-box;
    will-change: transform;
    &:nth-child(1) {
      animation: belching1 10s linear 0s infinite;
    }
    &:nth-child(2) {
      animation: belching2 10s linear 1s infinite;
    }
    &:nth-child(3) {
      animation: belching1 10s linear 2s infinite;
    }
    &:nth-child(4) {
      animation: belching2 10s linear 3s infinite;
    }
    &:nth-child(5) {
      animation: belching1 10s linear 4s infinite;
    }
    &:nth-child(6) {
      animation: belching2 10s linear 5s infinite;
    }
    &:nth-child(7) {
      animation: belching1 10s linear 6s infinite;
    }
    &:nth-child(8) {
      animation: belching2 10s linear 7s infinite;
    }
    &:nth-child(9) {
      animation: belching1 10s linear 8s infinite;
    }
    &:nth-child(10) {
      animation: belching2 10s linear 9s infinite;
    }
  }
}