.app-datepicker {
  position: fixed;
}
.DayPicker {
  &:focus {
    outline: none !important;
  }
  * {
    &:focus {
      outline: none !important;
    }
  }
  .DayPicker-WeekdaysRow {
    display: block;
    .DayPicker-Weekday {
      display: inline-block;
      width: 38px;
      height: 38px;
      margin: 2px;
    }
  }
  .DayPicker-Day {
    display: inline-block;
    width: 38px;
    height: 38px;
    margin: 2px;
    &:focus {
      outline: none !important;
    }
  }
  .DayPicker-Day.DayPicker-Day--publicHolidays.DayPicker-Day--disabled,
  .DayPicker-Day.DayPicker-Day--nonWorkingDays.DayPicker-Day--disabled{
    color: #ff9999 !important;
  }
  .DayPicker-Caption {
    select {
      display: inline-block;
      width: auto;
      margin-right: 0.3rem;
      height: 46px;
      min-width: 65px;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .DayPicker-NavBar {
    position: absolute;
    top: 1rem;
    right: 1rem;
    height: 46px;
    width: 40px;
    .DayPicker-NavButton {
      top: 50%;
      right: 50%;
      margin: 0 !important;
      &.DayPicker-NavButton--prev {
        right: calc(50% + 12px);
        transform: translate(50%, -50%);
      }
      &.DayPicker-NavButton--next {
        left: calc(50% + 12px);
        transform: translate(-50%, -50%);
      }
    }
  }
}
