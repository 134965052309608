.selection-box {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 105;
  opacity: 0;

  span {
    font-weight: 500;
    font-size: 1rem;
    margin-right: 1rem;
  }

  &.animate-appear {
    animation: appear 1s ease-out 0.2s forwards;
  }
}

.selectionBx {
  position: relative;
  display: inline-block;
  margin: 1rem 0;
}
