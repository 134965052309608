.card-node {
  display: inline-block;
  min-width: 274px;
  box-shadow: 0px 5px 10px 1px rgba(231,236,239,1);
  border-radius: 5px;
  overflow: hidden;
  &_container {
    display: flex;
    width: 100%;
  }
}
