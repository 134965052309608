.toggle-btns {
  width: 100%;
  justify-content: center;
  align-items: center;
  margin: 1rem 0 0;
  padding: 0 1rem;

  &.col {
    text-align: center;

    h3 {
      text-align: center;
    }

    .btns-list {
      width: 100%;
      justify-content: center;
      margin-top: 0.25rem;
    }
  }

  h3 {
    width: 30%;
    text-align: left;
    font-size: 1.5rem;
  }

  .btns-list {
    width: 70%;
    justify-content: space-between;

    .app-button {
      min-width: 100px;
      color: rgba(129, 138, 145, 1);
      padding: 1rem 0.5rem;
      margin: 0.2rem 0.5rem;
      border-radius: 30px;
      overflow: hidden;
      cursor: pointer;
      text-transform: capitalize;
      font-weight: 400;

      &.active {
        background-color: rgba(243, 246, 247, 1);
        color: rgba(79, 90, 96, 1);
        font-weight: 500;
      }
    }
  }
}
