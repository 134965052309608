.drawer-bg {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  display: block;
  background-color: transparent;

  &.open {
    background-color: rgba(0,0,0,0.3);
    opacity: 1;
    visibility: visible;
    z-index: 100;
  }

  &.close {
    background-color: transparent;
    opacity: 0;
    visibility: hidden;
    z-index: -10;
  }
}

.drawer {
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  height: fit-content;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #ffffff;
  bottom: -30%;
  transition: 0.3s;

  &.close-drawer {
    bottom: -30%;
    z-index: -1;
  }

  &.open-drawer {
    bottom: 0%;
    z-index: 101;
  }

  h4 {
    margin-bottom: .5rem;
    font-weight: 600;
  }

  .drawer-close-btn {
    display: inline-block;
    position: absolute;
    outline: none;
    top: 1.5rem;
    right: 1rem;
    z-index: 100;
    cursor: pointer;
    transform: scale(1.5);
  }
}
