.page-maintenance {
  .wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  .img-wrapper {
    max-width: 640px;

    img {
      width: 100%;
    }
  }

  .title {
    font-size: 1.5rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
}
