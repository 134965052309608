div.spot-light-bar {
  position: relative;
  max-width: 90%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  flex-direction: column;

  .sp-wrapper {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: flex-end;

    &__label {
      position: absolute;
      bottom: 26px;
      left: var(--targetWidth);

      .label-node {
        display: inline-block;
        position: absolute;
        top: 0;
        min-width: 100px;
        font-size: 0.75rem;
        padding: 0 0.5em;
        white-space: nowrap;
      }

      .label-stem {
        display: inline-block;
        width: 2px;
        height: 45px;
        bottom: -10px;
        border-left: 1px dashed #808991;
      }
    }

    &.origin-left {
      .label-node {
        left: 0;
        right: auto;
        border-left: 1px solid #808991;
        text-align: left;
      }
    }

    &.origin-right {
      .label-node {
        left: auto;
        right: 1px;
        border-right: 1px solid #808991;
        text-align: right;
      }
    }

    .spot-light__wrap-line {
      position: relative;
      width: 100%;
      height: 10px;
      background: var(--mainColor);
      border-radius: 10px;
      overflow: hidden;

      .line--result {
        border-radius: 10px;
        position: absolute;
        top: 0;
        left: 0;
        width: var(--resultWidth);
        height: 100%;
        background-color: var(--resultColor);
        animation: fullWidth 2.5s ease-in-out forwards;
        transform-origin: left;
      }
    }
  }

  &__content {
    display: flex;
    justify-content: space-between;
    margin-top: 0.375rem;
  }
}

@keyframes fullWidth {
  0% {
    transform: scaleX(0);
  }

  100% {
    transform: scaleX(1);
  }
}
