.docviewer {
    overflow: auto;
    width: 100vw;
    height: 100vh;
    max-width: 100vw;
    max-height: 100vh;
    justify-content: flex-start;

    .docviewer-loader {
        display: flex;
        width: 100%;
        height: 100%;
        background-color: #fff;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1000;

        svg {
            animation: rotateLoader 1s linear infinite;
        }
    }

    .react-pdf__Page {
        &__canvas {
            width: 645px !important;
            height: 892px !important;
        }

        &__textContent {
            width: 645px !important;
            height: 892px !important;
        }
    }

    .pinch-to-zoom-container {
        width: 80%;
        text-align: center;
        text-align: -webkit-center;
        .pinch-to-zoom-area {
            margin: auto;
        }
    }
}

@keyframes rotateLoader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}
