.chart-horizontal {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  padding: 1rem;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.04),
    0 4px 8px rgba(0, 0, 0, 0.04);

  .chart-horizon__top-content {
    width: 100%;
    border-radius: 5px;

    .chart-horizon-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .title {
        font-size: 1.75rem;
        font-weight: 600;
      }

      .mark {
        width: 25px;
        cursor: pointer;
      }
    }

    .chart-horizon-chart {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 1rem;

      .chart-label {
        display: flex;
        flex-direction: column;
        width: auto;

        .node-label {
          justify-content: flex-end;
        }
      }

      .chart-desc {
        display: flex;
        flex-direction: column;
      }

      .node-label,
      .node-desc {
        white-space: nowrap;
        margin-bottom: 1rem;
        font-size: 1rem;
        height: 48px;
        display: flex;
        align-items: center;

        @media screen and (max-width:374px) {
          font-size: 0.75rem;
        }
      }

      .chart-bar {
        display: flex;
        flex-direction: column;
        width: 100%;

        .wrapper {
          display: block;
          height: 48px;
          margin: 0 0.5rem 1rem;

          .bar {
            background-color: var(--ChartHorizonBgColor);
            width: var(--ChartHorizonWidth);
            height: 100%;
            border-radius: 0 5px 5px 0;
          }
        }
      }
    }
  }

  .chart-horizon__child-content {
    width: 100%;
  }
  @media only screen and (min-width: 320px) and (max-width: 736px) {
    .chart-horizon__top-content {
      .chart-horizon-header {
        .title {
          font-size: 1rem;
          font-weight: 600;
        }
      }

      .chart-horizon-chart {
        .node-label,
        .node-desc {
          font-size: 0.875rem;
          height: 24px;

          @media screen and (max-width:374px) {
            font-size: 0.75rem;
          }
        }

        .chart-bar {
          .wrapper {
            height: 24px;
          }
        }
      }
    }
  }
}
