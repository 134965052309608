.detail_section {
  margin: 0 auto;

  .detail_section--title {
    margin-bottom: 1rem;
  }

  .detail_section--content {
    padding-bottom: 2rem;

    .u-grid {
      padding-bottom: 0.5rem;
    }

    span {
      display: block;
    }

    .thumb {
      display: inline-block;
      width: 64px;
      height: 49px;
      border-radius: 5px;
      overflow: hidden;
      margin-right: 0.5rem;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .floormap-viewer {
      max-width: 575px;
      margin: 0.5rem auto;
      background: none;

      .viewer {
        border: 0;
        position: relative;
        padding: 0;

        .floormap {
          flex-basis: 100%;
        }
      }
    }

    .data {
      p {
        font-size: inherit;
        line-height: inherit;
      }
    }
  }

  .node-item--block {
    padding: 0.8rem 0;

    h4 {
      padding-bottom: 0.5rem;
    }

    .upload-input {
      padding: 0.5rem 0;
    }
  }

  .detail_section--group-box {
    display: block;
    text-align: left;
    padding: 1rem;
    border: 1px solid #dedede;
    border-radius: 5px;
    margin-bottom: 1rem;

    &:last-child {
      margin-bottom: 0;
    }

    .node-item--block {
      &:first-child {
        padding-top: 0;
      }
    }

    .lm--formItem {
      .lm--formItem-label {
        width: auto;
      }
    }

    .upload-input {
      margin-top: 0.5rem;

      .upload-label {
        width: 150px;
        justify-content: flex-start;
        text-align: left;
        padding-right: 1rem;
      }

      .upload_field {
        width: calc(100% - (240px + 1.438rem));
        flex-grow: 1;
      }
    }

    .check-grouped {
      .lm--formItem {
        .lm--formItem-label {
          width: 150px;
          justify-content: flex-start;
        }
      }
    }
  }

  .detail_section--checks {
    display: block;
    border-radius: 5px;
    padding: 1rem;

    .u-grid {
      padding-bottom: 0;

      .u-cell {
        padding-left: 0;
        padding-right: 0;
      }
    }

    .lm--formItem-label {
      display: none;
    }

    .lm--checkbox {
      .lm--checkbox-label {
        font-size: 0.8rem;

        &:before {
          flex-basis: 2.5rem;
        }
      }
    }

    .check-node {
      p {
        margin-left: 2rem;
      }
    }
  }

  .label {
    text-align: right;
    width: 30.5%;

    h4 {
      font-size: 1.5rem;
    }
  }

  .data {
    text-align: left;
    width: 69.5%;
    padding-top: 0.1rem;
  }
}
