.gfx-globecc {
  &.animate {
    .gfx-globecc-radiance {
      &.rad-1 {
        animation: pulse 2.5s linear 0.5s infinite;
      }
      &.rad-2 {
        animation: pulse 2.5s linear 0.75s infinite;
      }
    }

    .gfx-globecc-cloud {
      &.cloudco-1 {
        animation: appear 1s ease forwards, panningLeft 2s ease 0.5s infinite; 
      }
      &.cloudco-2 {
        animation: appear 1s ease 0.5s forwards, panningRight 2s ease 0.5s infinite;
      }
    }

    .gfx-globecc-flame {
      animation: flaming 2s ease infinite;
    }
  }
  .gfx-globecc-radiance {
    transform-box: fill-box;
    transform-origin: 50% 50%;
  }

  .gfx-globecc-cloud {
    transform-origin: 50% 50%;
    opacity: 0;
  }

  .gfx-globecc-flame {
    transform-origin: 50% 100%;
    transform: translateY(0);
  }
}
