.app-tnc {
  ol {
    margin: 1rem 0;
    li {
      // list-style: none;
      padding-bottom: 1rem;
      padding-left: 1rem;
    }
  }

}
