.gfx-trees {
  display: flex;
  flex-direction: row;
  // align-items: center;
  justify-content: flex-end;
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 10;

  .gfx-trees__section {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    flex-basis: 50%;
    position: relative;
  }

  .gfx-treegroup {
    z-index: 10;
    width: 50%;
    min-height: 50px;
    position: relative;
    transform: translateX(calc(var(--treeGroupOffsetX) * 1%)) translateY(calc(var(--treeGroupOffsetY) * 1%));

    .gfx-tree {
      $affectDelay: 1.5;
      $cloudDelay: 1;
      @for $i from 1 through 20 {
        
        &:nth-child(#{$i}) {
          &.is-affected {
            .co2-cloud_amount {
              animation: co2cloudAffectTheTree 1.5s ease-in-out #{$cloudDelay}s forwards;
            }
            animation: affectTree 1s ease-in-out #{$affectDelay}s forwards;
          }
        }
        $affectDelay : $affectDelay + 0.3;
        $cloudDelay : $cloudDelay + 0.3;
      }
    }
    .co2-cloud_amount {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%) translateY(-100%);
      z-index: 10;
      min-width: 100px;
      height: 50px;
      opacity: 0;
      transition: all 2s ease;
    }
    .co2-gfx_cloud {
      position: relative;
      width: 100%;
      height: 100%;
      z-index: 10;
      svg {
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        z-index: 10;
        transform: translateX(-50%) translateY(-50%);
      }
    }
  }

  .gfx-tree {
    transform-origin: 50% 100%;
    transform: translate(-50%, -100%) scale(var(--treeScale));
    position: absolute;

    &.is-affected {
      
      .co2-cloud_amount {
        transform: translateX(-50%) translateY(-80%);
        opacity: 1;
      }
    }

    .tree-overlay {
      opacity: var(--treeOverlayOpacity);
    }
  }
}

.gfx-tree {
  line-height: 0;
  position: relative;
  transition: all .5s ease;
  top: var(--treeY);
  left: var(--treeX);
  display: inline-block;
  z-index: 1;
  width: 157px;
  height: 137px;
  line-height: 0;

  &.chopped {
    .gfx-tree__stump {
      opacity: 1;
    }
    .gfx-tree1, .gfx-tree2 {
      opacity: 0;
    }
  }

  .gfx-tree1, .gfx-tree2 {
    position: relative;
    z-index: 2;
    width: 100%;
    height: 100%;
    transition: all .5s ease;
    opacity: 1;
  }
  
  .gfx-tree__stump {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    opacity: 0;
    transition: all .5s ease;
    width: 30%;
    height: 20px;
  }
}
