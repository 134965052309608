.terms-node {
    display: flex;
    position: relative;
    font-size: 0.875rem;
    flex-direction: row;
    text-align: left;
    width: 575px;
    margin: 0 auto;
    font-size: 1rem;
    line-height: 1.5;

    .terms-control {
        width: 32px;

        & + .terms-text {
            ol {
                //margin-left: -1.5rem;
            }
        }
    }

    .terms-text {
        padding-top: 0.2rem;
        ol {
            list-style-position: outside;
        }
    }

    .by-clicking {
        position: relative;
        display: flex;
    }

    span {
        display: inline !important;
    }

    a {
        display: inline;
    }

    ol {
        margin: 0;
        padding: 0.5rem 0;
        //margin-left: -0.3rem;
        margin-top: 0.5rem;

        ol {
            list-style-type: lower-alpha;
        }

        li {
            //padding-left: 0.5rem;
            margin-bottom: 0.4rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
