.app-page {
  display: block;
  width: 100%;
  min-height: 100vh;
  position: relative;

  &__container {
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 10;

    & > h1 {
      margin-bottom: 0.5rem;
      z-index: 10;
    }

    & > h3 {
      font-size: 1.3rem;
    }
  }

  &__sidebar {
    display: block;
    width: 240px;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
    transform: translateX(-100%);
    transition: all .5s ease;

    @include bg-gradient-vert;

    &.active {
      transform: translateX(0);
    }

    .app-contentnodes {
      display: block;
    }

    .app-contentnode {
      width: 100%;
      padding: 1rem 1rem 1rem 1.5rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);

      &:first-child {
        border-bottom: 0;
      }

      &:only-child {
        border-top: 1px solid rgba(255, 255, 255, 0.2);
      }

      &__value {
        color: #FFFFFF;
      }
    }
  }

  &__blade {
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: relative;
    .blade-container {
      max-width: 1198px;
      margin: 0 auto;
    }
  }

  .sidebar-toggler {
    display: block;
    width: 240px;
    height: 50px;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 5000;
    outline: none !important;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  }

  &.type-generic {
    .app-page__container {
      padding: calc(80px + 1rem) 10rem;
      justify-content: center;
      align-items: center;
      text-align: center;
      min-height: 100vh;

      & > div {
        width: 100%;
      }
    }
    .app-loader {
      color: $color-charcoal;
    }
  }

  &.type-profile,
  &.type-frame {
    display: flex;
    flex-direction: row;

    .app-page__sidebar {
      // width: 18.59vw;
      padding-bottom: calc(130px + 20px) 0;
      color: #ffffff;
    }

    .app-page__container {
      // width: 81.41vw;
      width: 100vw;
      height: 100vh;
      padding: 80px 20vw;
      overflow: auto;
      text-align: left;
    }

    .app-form {
      text-align: left;
      padding-right: 20%;

      .form-cta {
        .cta-button {
          flex-grow: initial;
        }
      }
    }

    .app-cta, .form-cta {
      margin-top: 3.8125rem;
    }

    h4 {
      margin-bottom: 2rem;
    }
  }

  &.type-profile {
    .app-form {
      padding: 0 8%;

      h1 {
        text-align: center;
        margin-top: 25%;
      }

      form {
        padding-top: 1rem;
      }
    }

    .app-loader {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &.type-frame {
    .app-page__container {
      padding: 0;
    }
  }

  &.type-landing {
    padding-bottom: 161px;
  }

  &.page-landing, &.page-questionnaire {
    .app-page__container {
      justify-content: flex-start;
    }
  }

  &.page-questionnaire {
    height: 100vh;
    max-height: 100vh;
    background-color: #F8FCFF;
    overflow: hidden;

    .cui-progress {
      &.is-crosswise {
        .cui-progress__items {
          width: 100%;
          .cui-progress__item {
            flex-basis: 6.5rem;
          }
        }
      }
    }

    .cui-progress-v2 {
      &.is-crosswise {
        .cui-progress-v2__items {
          width: 100%;
          .cui-progress-v2__item {
            flex-basis: 5rem;
          }
        }
      }
    }

    .app-qnnre__title {
      font-size: 1.6rem;
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }

    .app-qnnre__desc {
      font-size: 0.875rem;
      line-height: 1.7;
      max-width: 575px;
      margin: 0 auto;
    }

    .app-page__container {
      width: 100%;
      height: 100%;
      padding-top: 50px;
      padding-bottom: 25vh; 
    }

    .app-page__container.type-infinity {
      padding-bottom: 25px; 
    }

    .app-form {
      width: 100%;
      max-width: 675px;
      margin: 0 auto;
    }
  }
}

@import "./pages/menu.scss";
