.gfx-environment {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 35%;
  z-index: 5;
  pointer-events: none;
}

.gfx-buildings {
  position: absolute;
  top: -15%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  pointer-events: none;
}

.gfx-othertrees {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;
  pointer-events: none;
}
