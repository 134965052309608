.ie-edge {
  .app-herolanding {
    .hero-gfx__fg {
      .good-side {
        .gfx-biker {
          .gfx-biker-wheel_back{
            animation: none;
          }
          .gfx-biker-wheel_front{
            animation: none;
          }
        }
      }
    }
  }
  .gfx-co2logo {
    mask {
      path {
        fill: #ffffff !important;
      }
    }

    .gfx-co2logo-shadow {
      ellipse {
        fill: #000000;
        fill-opacity: 0.3 !important;
      }
    }
  }
  .gfx-factory__smoke {
    &.smoke2 {
      transform-origin: 50% 100%;
      transform: scale(0.5);
    }
    .smk {
      transform-box: fill-box;
      &:nth-child(1) {
        animation: belching1IE 10s linear 0s infinite;
      }
      &:nth-child(2) {
        animation: belching2IE 10s linear 1s infinite;
      }
      &:nth-child(3) {
        animation: belching1IE 10s linear 2s infinite;
      }
      &:nth-child(4) {
        animation: belching2IE 10s linear 3s infinite;
      }
      &:nth-child(5) {
        animation: belching1IE 10s linear 4s infinite;
      }
      &:nth-child(6) {
        animation: belching2IE 10s linear 5s infinite;
      }
      &:nth-child(7) {
        animation: belching1IE 10s linear 6s infinite;
      }
      &:nth-child(8) {
        animation: belching2IE 10s linear 7s infinite;
      }
      &:nth-child(9) {
        animation: belching1IE 10s linear 8s infinite;
      }
      &:nth-child(10) {
        animation: belching2IE 10s linear 9s infinite;
      }
    }
  }
  .app-page__container {
    scroll-snap-type: mandatory;
    scroll-snap-points-y: repeat(50vh);
    scroll-snap-type: y mandatory;
  }
  .page-results {
    &.printable-results {
      .results-tips {
        min-height: auto;
        justify-content: initial;
      }
    }
    .results-tips {
      min-height: 100vh;
      justify-content: center;
    }
    .results-share {
      min-height: 100vh;
      justify-content: center;
    }
  }
}
