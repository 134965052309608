.app-bgimg {
  display: flex;
  width: 100%;
  height: 100%;
  position: relative;

  &--overlay {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 5;
  }

  &--image {
    display: block;
    width: 100%;
    height: 100%;
    line-height: 0;
    position: relative;
    z-index: 1;
    line-height: 0;
    background-size: cover;
    background-position: center center;

    img {
      width: 100%;
      height: 100%;
      opacity: 0;
    }
  }

  &--footnote {
    display: inline-block;
    font-size: 0.75rem;
    color: #ffffff;
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    z-index: 10;
    line-height: 1;
  }

  &--content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 2;
  }
}
