.app-infocard {
  display: block;
  width: 100%;
  border-radius: 8px;
  box-shadow: 0px 2px 8px 0px rgba(0,0,0,.08);
  padding: 2rem;
  margin: 2rem auto;
  max-width: 575px;
  .infocard-content {
    display: flex;
    width: 100%;
  }

  &-small {
    padding: 1rem;
    box-shadow: none;
    border-bottom: 1px solid $grey200-colour;
    border-radius: 0;
  }
}
